import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import AlertIcon from "mdi-react/AlertOutlineIcon";
import { red } from "@material-ui/core/colors";
import { loadStripe } from "@stripe/stripe-js";

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: red[500],
    width: 100,
    height: 100,
  },
  errorHint: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const CheckoutBooking = ({ match }) => {
  const [stripe, setStripe] = useState(null);
  const [backToHome, setBackToHome] = useState(false);
  const [completed, setCompleted] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    if (!match.params.checkoutBookingUuid) {
      return;
    }
    setUuid(match.params.checkoutBookingUuid);
    makeStripe();
  }, [match.params.checkoutBookingUuid]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    var url = new URL(window.location);
    var clientSecret = url.searchParams.get("payment_intent_client_secret");

    stripe.retrievePaymentIntent(clientSecret).then(function (response) {
      if (response.error) {
        setError("Bei der Bezahlung ist ein Fehler aufgetreten");
      } else if (
        response.paymentIntent &&
        response.paymentIntent.status === "succeeded"
      ) {
        setCompleted(uuid);
      } else {
        setError("Bei der Bezahlung ist ein Fehler aufgetreten");
        console.log("ERROR");
      }
    });
  }, [stripe]);

  const makeStripe = async () => {
    if (
      !businessUnit ||
      !businessUnit.Payment ||
      !businessUnit.Payment.StripePublicKey
    ) {
      return;
    }
    const str = await loadStripe(businessUnit.Payment.StripePublicKey);
    setStripe(str);
  };

  if (completed) {
    return <Redirect push to={"/buchung/" + completed} />;
  }

  if (backToHome) {
    return <Redirect push to={"/"} />;
  }

  if (!businessUnit) {
    return null;
  }

  return (
    <>
      {!error && (
        <Box display="flex" alignItems="center" justifyContent="center" py={20}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          minHeight={200}
          p={8}
        >
          <AlertIcon className={classes.errorIcon} />
          <Typography
            variant="body1"
            align="center"
            className={classes.errorHint}
          >
            {error}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setBackToHome(true);
            }}
            className={classes.lateBtn}
            style={{
              backgroundColor: businessUnit.ColorButtonBackground,
              color: businessUnit.ColorButtonText,
            }}
          >
            zur Startseite
          </Button>
        </Box>
      )}
    </>
  );
};

export default CheckoutBooking;
