import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import API from "../utils/API";
import { useSelector } from "react-redux";

const SlotSeatsEdit = ({ slot, open, handleClose }) => {
  const [seats, setSeats] = useState(0);
  const inputRef = useRef(null);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!slot || !open) {
      return;
    }
    setSeats(slot.Seats);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  }, [open]);

  const handleChange = (event) => {
    setSeats(event.target.value);
  };

  const onSave = (e) => {
    e.preventDefault();
    const body = {
      seats,
    };
    API.put("slots/" + slot.id + "/seats", body, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((result) => {
        const slotResult = result.data ? result.data : null;
        // console.log(slotResult);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Dialog open={open} size="sm" fullWidth>
        <DialogTitle>Plätze bearbeiten</DialogTitle>
        <DialogContent>
          <form onSubmit={onSave}>
            <TextField
              inputRef={(r) => {
                inputRef.current = r;
              }}
              value={seats}
              onChange={handleChange}
              label="Plätze"
              variant="outlined"
              fullWidth
              type="number"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button variant="contained" onClick={onSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SlotSeatsEdit;
