import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Button,
  Toolbar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Container,
  Box,
  Typography,
  Snackbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useTheme } from "@material-ui/styles";
import ZaSeo from "./ZaSeo";
import useScreensize from "../hooks/useScreensize";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import BellIcon from "mdi-react/BellOutlineIcon";
import { grey, amber } from "@material-ui/core/colors";
import clsx from "clsx";
import News from "./News";
import Auth from "./Auth";
import { useSelector } from "react-redux";
import Favicon from "react-favicon";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      zIndex: 9999999,
    },
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    zIndex: 999,
  },
  menuButton: {
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  menuButtonWrapper: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 9999,
    width: drawerWidth,
    background: "#000",
    color: grey[600],
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  logo: {
    height: "80%",
    width: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      height: "auto",
    },
  },
  logoWrapper: {
    height: "100%",
    marginRight: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: 212,
      height: "auto",
      background: "#000",
      boxShadow: "0 0 10px rgba(0,0,0,0.5)",
      marginRight: 0,
      justifyContent: "center",
      marginTop: 0,
      borderRadius: theme.shape.borderRadius,
      alignSelf: "flex-start",
      paddingTop: 8,
      paddingBottom: 8,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  logoWrapperAuth: {
    boxShadow: "none",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      height: 64,
      "& > img": {
        height: "80%",
        width: "auto",
      },
    },
  },
  appBarMenu: {
    flexGrow: 1,
  },
  tb: {
    height: 1,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  btnBeratung: {
    marginRight: "15px",
  },
  desktopMenuIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  popover: {
    zIndex: 99999999,
    marginTop: theme.spacing(3),
  },
  popoverPaper: {
    background: "white",
    minWidth: 600,
  },
  menuUpIcon: {
    position: "absolute",
    right: 0,
    top: -30,
    width: 50,
    height: 50,
    color: "white",
  },
  menuImage: {
    backgroundSize: "cover",
  },
  menuItem: {
    borderRadius: 4,
  },
  drawerMenuItem: {
    borderRadius: 0,
    color: "#fff",
  },
  activeMenuItem: {
    background: "rgba(255,255,255,0.1)",
  },
  menuItemButton: {
    fontWeight: 600,
    minWidth: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#fff",
    textTransform: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      background: "rgba(255,255,255,0.05)",
    },
  },
  drawerLogo: {
    height: "auto",
    width: 100,
  },
  news: {
    width: 100,
    textAlign: "right",
    [theme.breakpoints.up("lg")]: {
      width: 270,
      marginLeft: 0,
    },
  },
}));

const Layout = ({ children, refs }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [activePath, setActivePath] = useState(null);
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);

  let menuItems = [
    {
      title: businessUnit.BookingLinkTitle || "Terminanmeldung",
      href: "/",
    },
    {
      title: businessUnit.DeliveryLinkTitle || "Hinweise zur Anlieferung",
      to: "/hinweise",
    },
    { title: "Häufige Fragen", to: "/faq" },
  ];

  if (businessUnit.MyBookingsTitle) {
    menuItems = [
      ...menuItems,
      {
        title: businessUnit.MyBookingsTitle,
        to: "/meine-buchungen/anfordern",
      },
    ];
  }

  if (auth && auth.token) {
    menuItems = [
      ...menuItems,
      {
        title: "Reservierungen",
        to: "/reservierungen",
      },
      {
        title: "QR-Check",
        to: "/check-shop-order",
      },
    ];
  }

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDesktopOpen(false);
  };

  if (!businessUnit) {
    return null;
  }

  const menu = businessUnit && !businessUnit.isBusinessUnitSelect && (
    <MenuList>
      {menuItems.map((item, index) => (
        <MenuItem
          className={clsx(
            classes.menuItem,
            classes.drawerMenuItem,
            (item.to === activePath || item.href === activePath) &&
              classes.activeMenuItem
          )}
          style={{ color: businessUnit.ColorMenuItemText }}
          button
          key={index}
          component={item.to ? Link : "a"}
          to={item.to ? item.to : undefined}
          href={item.href ? item.href : undefined}
          onClick={(event) => {
            setMobileOpen(false);
          }}
        >
          {item.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const drawer = (
    <div>
      <a href="/">
        <Box p={2}>
          <img
            src={businessUnit.Logo.url}
            alt=""
            className={classes.drawerLogo}
          />
        </Box>
      </a>
      <Divider />
      {menu}
    </div>
  );

  const seo = Boolean(businessUnit.SeoTitle) && (
    <ZaSeo
      title={businessUnit.SeoTitle}
      description={businessUnit.SeoDescription}
    />
  );

  return (
    <>
      {businessUnit.Favicon && <Favicon url={businessUnit.Favicon.url} />}

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={{ backgroundColor: businessUnit.ColorMenuBackground }}
          className={classes.appBar}
        >
          <Toolbar className={classes.tb}>
            <Container maxWidth="lg">
              <Box display="flex" height={xs ? 56 : 64} alignItems="center">
                <Box width={100} className={classes.menuButtonWrapper}>
                  <IconButton
                    color="primary"
                    aria-label="Open drawer"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <a
                  className={clsx(
                    classes.logoWrapper,
                    auth && auth.token && classes.logoWrapperAuth
                  )}
                  style={{
                    backgroundColor: xs
                      ? "transparent"
                      : businessUnit.ColorLogoBoxBackground,
                    width: auth && auth.token ? 100 : undefined,
                  }}
                  href="/"
                >
                  <img
                    src={
                      auth && auth.token && businessUnit.LogoAuth
                        ? businessUnit.LogoAuth.url
                        : businessUnit.Logo.url
                    }
                    alt=""
                    className={classes.logo}
                  />
                </a>
                <Hidden mdDown>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginLeft="auto"
                    marginRight="auto"
                    paddingLeft={auth && auth.token ? undefined : "48px"}
                    justifyContent="center"
                  >
                    {businessUnit &&
                      !businessUnit.isBusinessUnitSelect &&
                      menuItems.map((item, index) => (
                        <Button
                          key={index}
                          component={
                            item.action ? undefined : item.href ? "a" : Link
                          }
                          to={item.to ? item.to : undefined}
                          href={item.href ? item.href : undefined}
                          color="primary"
                          style={{
                            color: businessUnit.ColorMenuItemText,
                            backgroundColor:
                              (item.to === activePath ||
                                item.href === activePath) &&
                              (businessUnit.ColorMenuItemActiveBackground ||
                                undefined),
                          }}
                          className={clsx(classes.menuItemButton, {
                            [classes.activeMenuItem]: item.to === activePath,
                          })}
                        >
                          {item.title}
                        </Button>
                      ))}
                  </Box>
                </Hidden>
                <div
                  className={classes.news}
                  style={auth && auth.token ? { width: 100 } : undefined}
                >
                  {businessUnit && !businessUnit.isBusinessUnitSelect && (
                    <>
                      <News />
                      <Auth />
                    </>
                  )}
                </div>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden lgUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              PaperProps={{
                style: { backgroundColor: businessUnit.ColorMenuBackground },
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        {desktopOpen && (
          <Popper
            className={classes.popover}
            open={desktopOpen}
            anchorEl={anchorRef.current}
            keepMounted
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} in={desktopOpen}>
                <Paper id="menu-list-grow" className={classes.popoverPaper}>
                  <MenuUpIcon className={classes.menuUpIcon} />
                  <ClickAwayListener onClickAway={handleDesktopClose}>
                    <Box display="flex" p={4}>
                      <Box flexGrow={1} flexShrink={0} flexBasis="1px" mr={3}>
                        {menu || <></>}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
        <Footer />
        {seo}
      </div>
    </>
  );
};

export default Layout;
