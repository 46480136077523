import API from "../../utils/API";

export const getData = () => async (dispatch, getState) => {
  API.get("shop-products")
    .then((productsData) => {
      const products = productsData.data ? productsData.data : [];
      dispatch({
        type: "SET_SHOP_PRODUCTS",
        products,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_SHOP_PRODUCTS",
        locations: [],
      });
    });
};
