import React, { useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Button,
  Divider,
} from "@material-ui/core";
import API from "../../utils/API";
import { useState } from "react";
import { red } from "@material-ui/core/colors";
import Moment from "react-moment";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  error: {
    color: red[500],
  },
}));

const MyBookings = ({ match }) => {
  const [error, setError] = useState(null);
  const [bookings, setBookings] = useState([]);
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  useEffect(() => {
    if (!match.params.uuid) {
      return;
    }

    setError(null);

    API.get("/booking-reports/report/" + match.params.uuid)
      .then((result) => {
        const reportResult = result.data ? result.data : null;
        if (reportResult && reportResult.length) {
          setBookings(reportResult);
        } else {
          setError(`Es wurden keine aktuellen Buchungen gefunden`);
        }
      })
      .catch((error) => {
        setError(`Es wurden keine aktuellen Buchungen gefunden`);
      });
  }, [match.params.uuid]);

  if (!businessUnit) {
    return null;
  }

  return (
    <Container maxWidth="md">
      <Box p={{ xs: 1, sm: 10 }} my={3}>
        {Boolean(error) && (
          <Paper>
            <Box px={3} py={1} textAlign="center">
              <Typography variant="body2" className={classes.error}>
                {error}
              </Typography>
            </Box>
          </Paper>
        )}
        {Boolean(bookings && bookings.length) && (
          <>
            <Box mb={3}>
              <Typography variant="h1" align="center" gutterBottom>
                Meine aktuellen Buchungen
              </Typography>
            </Box>
            <Paper>
              <Box p={3}>
                <Grid container spacing={3}>
                  {bookings.map((booking, idx) => (
                    <React.Fragment key={idx}>
                      <Grid item xs={6} sm={4}>
                        <strong>
                          <Moment locale="de" format="ddd, DD.MM.YYYY">
                            {booking.date}
                          </Moment>
                        </strong>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Moment locale="de" format="HH:mm" parse="HH:mm:ss.SSS">
                          {booking.from}
                        </Moment>
                        {" - "}
                        <Moment locale="de" format="HH:mm" parse="HH:mm:ss.SSS">
                          {booking.to}
                        </Moment>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          fullWidth
                          size="small"
                          component="a"
                          target="_blank"
                          variant="contained"
                          color="primary"
                          style={{
                            backgroundColor: businessUnit.ColorButtonBackground,
                          }}
                          href={
                            (process.env[
                              "REACT_APP_API_URL" + window.ENV_POSTFIX
                            ] || process.env.REACT_APP_API_URL) +
                            "bookings/download/" +
                            booking.uuid
                          }
                        >
                          PDF herunterladen
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            </Paper>
          </>
        )}
      </Box>
    </Container>
  );
};

export default MyBookings;
