import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import { grey, yellow, teal } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  divider: {
    backgroundColor: grey[400],
  },
  val: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  highlight: {
    background: teal[50],
  },
}));

const BookingPaymentSummary = ({ booking }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || !booking) {
    return null;
  }

  const paymentKinds = {
    paypal: "PayPal",
    creditCard: "Kreditkarte",
    giroPay: "Giropay",
    ec: "EC-Karte",
  };

  const paymentResultKinds = {
    settling: "In Bearbeitung",
    settled: "abgeschlossen",
  };

  const noPaymentKind = "keine Auswahl";
  const noPaymentResult = "unbekannt";

  const selections = [
    {
      title: "Zahlungsart",
      value: booking.shop_order.PaymentKind
        ? paymentKinds[booking.shop_order.PaymentKind] || noPaymentKind
        : noPaymentKind,
    },
  ];

  return (
    <>
      <Box className={classes.list}>
        {selections.map((item, idx, arr) => (
          <React.Fragment key={idx}>
            <Box p={2} display="flex">
              <Box
                width={120}
                flexShrink={0}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body1" component="span">
                  <strong>{item.title}</strong>
                </Typography>
              </Box>
              <Box flexGrow={1} textAlign="right">
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.val}
                >
                  {item.value}
                </Typography>
              </Box>
            </Box>
            {idx < arr.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default BookingPaymentSummary;
