import React, { useState, useEffect } from "react";
import * as authActions from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
    setTimeout(() => {
      setRedirect(true);
    }, 250);
  }, []);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return null;
};

export default Logout;
