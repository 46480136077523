import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import API from "../utils/API";

const ShopOrderCodeInput = ({ open, handleCheckCode, handleClose }) => {
  const [code, setCode] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (!open) {
      return;
    }
    setCode("");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  }, [open]);

  const handleChange = (event) => {
    setCode(event.target.value.toUpperCase());
  };

  const onSave = (e) => {
    e.preventDefault();
    if (!code) {
      return;
    }
    handleCheckCode(code);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} size="sm" fullWidth>
        <DialogTitle>Code überprüfen</DialogTitle>
        <DialogContent>
          <form onSubmit={onSave}>
            <TextField
              inputRef={(r) => {
                inputRef.current = r;
              }}
              value={code}
              onChange={handleChange}
              label="Check-In Code"
              variant="outlined"
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button variant="contained" onClick={onSave}>
            Prüfen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopOrderCodeInput;
