import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ShopOrderQRScan from "../ShopOrderQRScan";
import ShopOrderDetails from "../ShopOrderDetails";
import API from "../../utils/API";
import ShopOrderCheckInOutSuccess from "../ShopOrderCheckInOutSuccess";
import ShopOrderCodeInput from "../ShopOrderCodeInput";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
    minHeight: "calc(100vh - 126px)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
}));

const CheckShopOrder = () => {
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [redirect, setRedirect] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const [success, setSuccess] = useState(null);
  const [openCodeInput, setOpenCodeInput] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!auth || !auth.token) {
      setRedirect(true);
    }
  }, [auth]);

  useEffect(() => {
    if (businessUnit && businessUnit.isBusinessUnitSelect) {
      setRedirect(true);
    }
  }, [businessUnit]);

  const handleScan = (data) => {
    if (!data) {
      return;
    }
    setScanResult(data);
    // console.log("SUCCESS", data);
    setShowScanner(false);
  };

  const handleError = (err) => {
    // console.log("ERROR", err);
    setScanResult(null);
    setShowScanner(false);
  };

  const handleCancel = () => {
    setScanResult(null);
    setSuccess(null);
    setShowScanner(false);
  };

  const handleNextScan = () => {
    setScanResult(null);
    setSuccess(null);
    setShowScanner(true);
  };

  const handleOpenCodeInput = () => {
    setOpenCodeInput(true);
  };

  const handleCloseCodeInput = () => {
    setOpenCodeInput(false);
  };

  const handleCheckCodeInput = (code) => {
    setScanResult(code);
  };

  const handleCheckIn = (uuid, reservedPlaces) => {
    API.post(
      "bookings/check-in/" + uuid,
      { status: true, reservedPlaces },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then((bookingData) => {
        // console.log(slotsData);
        const res = bookingData.data ? bookingData.data : null;
        // console.log(res);
        if (res && res.uuid) {
          setScanResult(null);
          setSuccess("checkedIn");
        }
      })
      .catch((error) => {});
  };

  const handleCheckOut = (uuid) => {
    API.post(
      "bookings/check-out/" + uuid,
      { status: true },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then((bookingData) => {
        // console.log(slotsData);
        const res = bookingData.data ? bookingData.data : null;
        // console.log(res);
        if (res && res.uuid) {
          setScanResult(null);
          setSuccess("checkedOut");
        }
      })
      .catch((error) => {});
  };

  if (redirect) {
    return <Redirect to="/logout" />;
  }

  let containerStyle;
  if (scanResult || !businessUnit.QRScanBackgroundImage) {
    containerStyle = undefined;
  } else if (showScanner) {
    containerStyle = { backgroundColor: "#222" };
  } else {
    containerStyle = {
      backgroundImage: `url(${businessUnit.QRScanBackgroundImage.url})`,
    };
  }

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.container}
        style={containerStyle}
      >
        {!Boolean(success) && !scanResult && showScanner && (
          <ShopOrderQRScan
            handleScan={handleScan}
            handleError={handleError}
            onCancel={handleCancel}
          />
        )}

        {!Boolean(success) && !scanResult && !showScanner && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pt={10}
          >
            <Paper>
              <Box
                p={8}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {Boolean(businessUnit.QRScanTitle) && (
                  <Box mb={3}>
                    <Typography variant="h1" align="center">
                      {businessUnit.QRScanTitle}
                    </Typography>
                  </Box>
                )}
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setShowScanner(true);
                  }}
                  style={{
                    backgroundColor: businessUnit.ColorButtonBackground,
                    color: businessUnit.ColorButtonText || "#fff",
                  }}
                >
                  QR-Code scannen
                </Button>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={handleOpenCodeInput}
                  >
                    Manuelle Eingabe
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}

        {!Boolean(success) && scanResult && (
          <ShopOrderDetails
            searchId={scanResult}
            onCancel={handleCancel}
            onCheckIn={handleCheckIn}
            onCheckOut={handleCheckOut}
          />
        )}

        {Boolean(success) && (
          <ShopOrderCheckInOutSuccess
            onNextScan={handleNextScan}
            onCancel={handleCancel}
          />
        )}
      </Container>
      <ShopOrderCodeInput
        open={openCodeInput}
        handleCheckCode={handleCheckCodeInput}
        handleClose={handleCloseCodeInput}
      />
    </>
  );
};

export default CheckShopOrder;
