const initState = {
  reservations: {},
};

const reservationReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_RESERVATION":
      const r = Object.assign({}, state.reservations);
      r[action.slotId] = action.reservation;
      return {
        ...state,
        reservations: r,
      };
    case "REMOVE_RESERVATION":
      const res = Object.assign({}, state.reservations);
      delete res[action.slotId];
      // console.log("REMOVE", res, action.slotId);
      return {
        ...state,
        reservations: res,
      };
    default:
      return state;
  }
};

export default reservationReducer;
