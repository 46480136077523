import API from "../../utils/API";

export const getData = () => async (dispatch, getState) => {
  API.get("warning")
    .then((warningData) => {
      // console.log(warningData);
      const warningResult = warningData.data ? warningData.data : null;
      if (!warningResult || !warningResult.Active) {
        dispatch({
          type: "SET_BREAKING_NEWS",
          hidden: false,
          content: "",
        });
        return;
      }
      const existing = getState().breakingNews;
      // console.log(existing.updatedAt, warningResult.updated_at);
      if (existing && existing.updatedAt === warningResult.updated_at) {
        return;
      }
      if (warningResult.Active) {
        dispatch({
          type: "SET_BREAKING_NEWS",
          hidden: false,
          content: warningResult.Body,
          date: warningResult.updated_at,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "SET_BREAKING_NEWS",
        hidden: false,
        content: "",
      });
    });
};

export const close = () => (dispatch) => {
  dispatch({
    type: "SET_CLOSE",
  });
};
