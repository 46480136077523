import API from "../../utils/API";

export const getData = () => async (dispatch, getState) => {
  API.get("locations")
    .then((locationsData) => {
      // console.log(locationsData);
      const locationsResult = locationsData.data ? locationsData.data : [];
      dispatch({
        type: "SET_LOCATIONS",
        locations: locationsResult,
      });
    })
    .catch((error) => {
      dispatch({
        type: "SET_LOCATIONS",
        locations: [],
      });
    });
};
