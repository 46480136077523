import React from "react";
import QrReader from "react-qr-reader";
import { Box, makeStyles, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  topMenu: {
    position: "fixed",
    background: grey[900],
    top: 64,
    left: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      top: 56,
    },
  },
  topMenuBtn: {
    color: "#fff",
    marginLegt: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const previewStyle = {
  width: "80vw",
  maxWidth: "80vh",
  height: "80vw",
  maxHeight: "80vh",
};

const ShopOrderQRScan = ({ handleError, handleScan, onCancel }) => {
  const classes = useStyles();
  return (
    <>
      <Box
        p={2}
        className={classes.topMenu}
        display="flex"
        justifyContent="center"
      >
        <Button className={classes.topMenuBtn} onClick={onCancel}>
          Abbrechen
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <QrReader
          delay={300}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          facingMode="environment"
        />
      </Box>
    </>
  );
};

export default ShopOrderQRScan;
