import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
    fontSize: "1.4rem",
    lineHeight: 1.3,
    marginTop: theme.spacing(3),
  },
}));

const Faq = () => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (businessUnit && businessUnit.isBusinessUnitSelect) {
      setRedirect(true);
    }
  }, [businessUnit]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  if (!businessUnit || businessUnit.isBusinessUnitSelect) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                FAQ
              </Typography>
              <Typography
                variant="h2"
                className={classes.subtitle}
                color="textSecondary"
              >
                {businessUnit.FaqSubtitle}
              </Typography>
            </Box>
          </Paper>
          <Box py={{ xs: 2, md: 3 }}>
            {Boolean(businessUnit.Faq.length) &&
              businessUnit.Faq.map((f, idx) => (
                <ExpansionPanel key={idx}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle2">{f.Question}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography variant="body2" component="div">
                      <ReactMarkdown source={f.Answer} />
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Faq;
