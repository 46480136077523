export const setToken = (token) => (dispatch) => {
  dispatch({
    type: "SET_TOKEN",
    token,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: "SET_TOKEN",
    token: null,
  });
};
