import React from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Fab,
} from "@material-ui/core";
import TsDatePicker from "@bit/wamedia.tasso.ts-date-picker";
import useValues from "@bit/wamedia.tasso.use-values";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";
import { useState } from "react";
import { useEffect } from "react";
import API from "../../utils/API";
import moment from "moment";
import Moment from "react-moment";
import SlotBookings from "../SlotBookings";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Chart from "../Chart";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CloseIcon from "mdi-react/CloseIcon";
import DownloadIcon from "mdi-react/FileTableIcon";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 116,
  },
  mb: {
    marginBottom: theme.spacing(4),
  },
  header: {
    position: "fixed",
    top: 64,
    left: 0,
    right: 0,
    borderRadius: 0,
    zIndex: 1,
  },
  arrowLeft: {
    width: 40,
    height: 40,
  },
  arrowRight: {
    width: 40,
    height: 40,
  },
  selectedDate: {
    width: 400,
  },
  arrowBtnDisabled: {
    opacity: 0.4,
  },
  downloadBtn: {
    position: "fixed",
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    color: "#fff",
    zIndex: 9,
  },
}));

const initialValues = {
  to: null,
  search: "",
  onlyPhoneBooking: false,
  days: "today",
  showSearch: false,
};

const Bookings = () => {
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [availableDates, setAvailableDates] = useState([]);
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!auth || !auth.token) {
      setRedirect(true);
    }
  }, [auth]);

  useEffect(() => {
    if (businessUnit && businessUnit.isBusinessUnitSelect) {
      setRedirect(true);
    }
  }, [businessUnit]);

  useEffect(() => {
    setValues({
      to: moment().format("YYYY-MM-DD"),
      days: "today",
      search: "",
      onlyPhoneBooking: false,
      showSearch: false,
    });
  }, []);

  useEffect(() => {
    if (!auth || !auth.token) {
      setRedirect(true);
      return;
    }

    setSelectedDate(0);

    if (!values.to) {
      return;
    }

    const from = moment(values.to)
      .subtract(values.days === "today" ? 0 : values.days, "days")
      .format("YYYY-MM-DD");

    API.get("slots/bookings/" + from + "/" + values.to, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((slotsData) => {
        console.log(slotsData);
        const slotsResult = slotsData.data ? slotsData.data : [];
        /***** TEST */
        /*
        for (let i = 0; i < 300; i++) {
          const fake = Object.assign({}, slotsResult[0].bookings[0]);
          fake.id += i;
          fake.FirstName = "Foo";
          slotsResult[0].bookings.push(fake);
        }
        */
        /***** END TEST */
        setSlots(
          slotsResult.sort((a, b) => {
            if (a.Date !== b.Date) {
              return a.Date > b.Date ? 1 : -1;
            }
            return a.From > b.From ? 1 : -1;
          })
        );
        setAvailableDates(
          [...new Set(slotsResult.map((m) => m.Date))].sort((a, b) =>
            a > b ? 1 : -1
          )
        );
      })
      .catch((error) => {
        setSlots([]);
      });
  }, [values.to, values.days]);

  const visibleSlots = slots.filter(
    (f) => f.Date === availableDates[selectedDate]
  );

  const onNext = () => {
    setSelectedDate((state) =>
      state < availableDates.length - 1 ? state + 1 : 0
    );
  };

  const onPrev = () => {
    setSelectedDate((state) =>
      state > 0 ? state - 1 : availableDates.length - 1
    );
  };

  const onCheckIn = (uuid, status) => {
    API.post(
      "bookings/check-in/" + uuid,
      { status: !status },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then((bookingData) => {
        // console.log(slotsData);
        const res = bookingData.data ? bookingData.data : null;
        // console.log(res);
        // console.log(slots);

        if (res && res.uuid) {
          const slotsCopy = slots.slice();
          for (const s of slotsCopy) {
            for (const b of s.bookings) {
              if (b.uuid === res.uuid) {
                b.CheckedIn = res.CheckedIn;
                b.CheckedInAt = res.CheckedInAt;
              }
            }
          }
          setSlots(slotsCopy);
        }
      })
      .catch((error) => {});
  };

  const onCheckOut = (uuid, status) => {
    API.post(
      "bookings/check-out/" + uuid,
      { status: !status },
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    )
      .then((bookingData) => {
        // console.log(slotsData);
        const res = bookingData.data ? bookingData.data : null;
        // console.log(res);
        // console.log(slots);

        if (res && res.uuid) {
          const slotsCopy = slots.slice();
          for (const s of slotsCopy) {
            for (const b of s.bookings) {
              if (b.uuid === res.uuid) {
                b.CheckedOut = res.CheckedOut;
                b.CheckedOutAt = res.CheckedOutAt;
              }
            }
          }
          setSlots(slotsCopy);
        }
      })
      .catch((error) => {});
  };

  const onSave = (values, uuid, callback) => {
    // console.log(values);
    API.put("bookings/edit/" + uuid, values, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((bookingData) => {
        // console.log(slotsData);
        const res = bookingData.data ? bookingData.data : null;
        // console.log(res);
        // console.log(slots);

        if (res && res.uuid) {
          const slotsCopy = slots.slice();
          for (const s of slotsCopy) {
            for (let i = 0; i < s.bookings.length; i++) {
              if (s.bookings[i].uuid === res.uuid) {
                s.bookings[i] = res;
              }
            }
          }
          setSlots(slotsCopy);
          callback();
        }
      })
      .catch((error) => {});
  };

  if (redirect) {
    return <Redirect to="/logout" />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.header}>
        <Container maxWidth="lg">
          <Box px={1} py={2}>
            <Grid container spacing={4}>
              {values.showSearch && (
                <Grid
                  item
                  xs={12}
                  sm={Boolean(businessUnit.Stats.OnlyPhoneFilterEmail) ? 3 : 5}
                >
                  <TsTextField
                    value={values.search}
                    handleChange={handleChangeValue}
                    name="search"
                    label="Suchen..."
                    endAdornment={
                      values.search && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleChangeValue("search", "")();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={2} lg={1}>
                <Box pt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.showSearch}
                        onChange={(e) => {
                          handleChangeValue("showSearch")(e);
                        }}
                        color="primary"
                      />
                    }
                    label="Suche"
                  />
                </Box>
              </Grid>
              {Boolean(businessUnit.Stats.OnlyPhoneFilterEmail) && (
                <Grid item xs={12} sm={2}>
                  <Box pt={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.onlyPhoneBooking}
                          onChange={(e) => {
                            handleChangeValue("onlyPhoneBooking")(e);
                          }}
                          color="primary"
                        />
                      }
                      label="Nur Tel."
                    />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                <TsDatePicker
                  value={values.to}
                  label="Datum (bis)"
                  name="to"
                  handleChange={handleChangeValue}
                  disableFuture={false}
                />
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Zeitraum
                  </InputLabel>
                  <Select
                    native
                    value={values.days}
                    onChange={(e) => {
                      handleChangeValue("days")(e);
                    }}
                    label="Zeitraum"
                    inputProps={{
                      name: "days",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value={"today"}>1 Tag</option>
                    <option value={7}>7 Tage</option>
                    <option value={30}>30 Tage</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
      <Chart slots={slots} sumDays={values.days !== "today"} />

      <Container maxWidth="lg">
        <Paper className={classes.mb}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            bgcolor={businessUnit.ColorHeaderBackground}
            color={businessUnit.ColorHeaderText}
            height={96}
          >
            {availableDates.length > 1 && (
              <IconButton
                onClick={onPrev}
                disabled={selectedDate === 0}
                className={clsx(selectedDate === 0 && classes.arrowBtnDisabled)}
              >
                <ChevronLeftIcon className={classes.arrowLeft} />
              </IconButton>
            )}

            <Typography
              variant="h3"
              className={classes.selectedDate}
              align="center"
            >
              <Moment locale="de" format="dddd, D. MMMM YYYY">
                {availableDates[selectedDate]}
              </Moment>
            </Typography>
            {availableDates.length > 1 && (
              <IconButton
                onClick={onNext}
                disabled={selectedDate === availableDates.length - 1}
                className={clsx(
                  selectedDate === availableDates.length - 1 &&
                    classes.arrowBtnDisabled
                )}
              >
                <ChevronRightIcon className={classes.arrowRight} />
              </IconButton>
            )}
          </Box>
          <Box p={4}>
            {visibleSlots.map((slot, idx) => (
              <SlotBookings
                slot={slot}
                showSearch={values.showSearch}
                search={values.search}
                onlyPhoneBooking={values.onlyPhoneBooking}
                key={idx}
                onCheckIn={onCheckIn}
                onCheckOut={onCheckOut}
                onSave={onSave}
              ></SlotBookings>
            ))}
          </Box>
        </Paper>
      </Container>
      {Boolean(
        businessUnit.CsvReport && businessUnit.CsvReport.CsvReportField
      ) && (
        <Fab
          disabled={!slots || !slots.length}
          color="primary"
          component={CSVLink}
          filename={`Buchungen_${availableDates[selectedDate]}_${
            values.days === "today" ? "1Tag" : values.days + "Tage"
          }.csv`}
          data={slots.reduce((arr, cur) => {
            const bookings = cur.bookings.map((booking) =>
              [
                moment(cur.Date).format("DD.MM.YYYY"),
                moment(cur.From, "HH:mm:ss:SSSS").format("HH:mm"),
                moment(cur.To, "HH:mm:ss:SSSS").format("HH:mm"),
                ...businessUnit.CsvReport.CsvReportField.map((field) => {
                  if (field.AttributePath === "AdditionalPersons") {
                    // console.log(booking);
                    const persons = _.get(booking, field.AttributePath);
                    if (!persons || !persons.length) {
                      return "";
                    }
                    return persons
                      .map((m) => `${m.FirstName} ${m.LastName}`)
                      .join(", ");
                  }
                  if (!field.TargetDateFormat && !field.BooleanTrueText) {
                    return _.get(booking, field.AttributePath);
                  }
                  if (field.BooleanTrueText) {
                    return _.get(booking, field.AttributePath)
                      ? field.BooleanTrueText
                      : "";
                  }
                  if (field.OriginalDateFormat && field.TargetDateFormat) {
                    return moment(
                      _.get(booking, field.AttributePath),
                      field.OriginalDateFormat
                    ).format(field.TargetDateFormat);
                  }
                  if (field.TargetDateFormat) {
                    return moment(_.get(booking, field.AttributePath)).format(
                      field.TargetDateFormat
                    );
                  }
                  return "";
                }),
              ].map((cell) => (cell ? cell.replace(/"/g, '""') : cell))
            );
            const newArr = [...arr, ...bookings];
            return newArr;
          }, [])}
          className={classes.downloadBtn}
        >
          <DownloadIcon />
        </Fab>
      )}
    </div>
  );
};

export default Bookings;

/*
booking.NumberPlate,
              booking.LastName,
              booking.FirstName,
              booking.Email,
              booking.Wsh ? "WSH" : "",
              booking.Sonderabfall ? "SOA" : "",
              booking.Street,
              booking.StreetNumber,
              booking.location.Zip,
              booking.location.Name,
              booking.IsCancelled ? "storniert" : "",
*/
