import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";
import {
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactMarkdown from "react-markdown";
import { red, grey, amber } from "@material-ui/core/colors";
import useScreensize from "../hooks/useScreensize";

const useStyles = makeStyles((theme) => ({
  streetNumber: {
    "& > .MuiFormControl-root": {
      minWidth: "80px !important",
    },
  },
  firstName: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "0 !important",
    },
  },
  lastName: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0 !important",
    },
  },
  btnBooking: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#fff",
  },
  btnCancel: {
    marginBottom: theme.spacing(1),
  },
  privacyText: {
    fontSize: 12,
  },
  privacyLabel: {
    fontSize: 14,
    position: "relative",
    top: -2,
    fontWeight: 700,
  },
  mdText: {
    "&>p": {
      margin: 0,
    },
  },
  additionalBox: {
    borderRadius: theme.shape.borderRadius,
    "& input": {
      backgroundColor: "#fff !important",
    },
  },
}));

const BookingForm = ({
  values,
  handleChangeValue,
  locations,
  acceptPrivacy,
  handleChangePrivacy,
  isDisabled,
  onClick,
  btnText,
  onPrev,
  kidsAreaAvailable,
  additionalPersons,
  setAdditionalPersons,
}) => {
  useEffect(() => {
    console.log("BOOKING FORM VALUES", values);
    if (!values || !values.location || !locations || !locations.length) {
      return;
    }


    const preselectedLocation = locations.find(f => f.id === values.location);
    if (!preselectedLocation) {
      return;
    }

    console.log('setLocationInputValue', preselectedLocation);
    setLocationInputValue(`${preselectedLocation.Zip} ${preselectedLocation.Name}`);
  }, []);

  const [locationInputValue, setLocationInputValue] = useState("");
  const businessUnit = useSelector((state) => state.businessUnit);
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();

  if (!businessUnit) {
    return null;
  }

  const handleChangeNumberPlate = (name) => (event, foo) => {
    console.log(name, event && event.target.value, foo);

    let normalizedNumberPlate = "";

    if (event && event.target && event.target.value) {
      normalizedNumberPlate = event.target.value;
    }

    normalizedNumberPlate = normalizedNumberPlate.replace(/[^a-z0-9]/gi, '');
    normalizedNumberPlate = normalizedNumberPlate.toUpperCase();

    if (normalizedNumberPlate && normalizedNumberPlate.length > 8) {
      return;
    }

    handleChangeValue(name, normalizedNumberPlate)(event);
  }

  

  return (
    <>
      {businessUnit.InputFields && businessUnit.InputFields.NumberPlate && (
        <>
          <TsTextField
            value={values.NumberPlate}
            handleChange={handleChangeNumberPlate}
            name="NumberPlate"
            label="Amtliches Kennzeichen des Anlieferfahrzeugs"
          />
          <Box mb={2}>
            <Typography variant="body2" className={classes.privacyText}>
            Die Eingabe des Kennzeichens erfolgt ohne Leerzeichen und Bindestriche.
            </Typography>
          </Box>
        </>
      )}
      {businessUnit.InputFields && businessUnit.InputFields.Email && (
        <TsTextField
          value={values.Email}
          handleChange={handleChangeValue}
          name="Email"
          label="E-Mail-Adresse"
        />
      )}
      {businessUnit.InputFields && businessUnit.InputFields.PhoneNumber && (
        <TsTextField
          value={values.PhoneNumber}
          handleChange={handleChangeValue}
          name="PhoneNumber"
          label="Telefonnummer"
        />
      )}
      <Grid container spacing={2}>
        {businessUnit.InputFields && businessUnit.InputFields.FirstName && (
          <Grid item xs={12} md={6} className={classes.firstName}>
            <TsTextField
              value={values.FirstName}
              handleChange={handleChangeValue}
              name="FirstName"
              label="Vorname"
            />
          </Grid>
        )}

        {businessUnit.InputFields && businessUnit.InputFields.LastName && (
          <Grid item xs={12} md={6} className={classes.lastName}>
            <TsTextField
              value={values.LastName}
              handleChange={handleChangeValue}
              name="LastName"
              label="Nachname"
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {[
          {
            name: "Wsh",
            label: "Wertstoffhof",
            show: businessUnit.Wsh,
            available: true,
            checkedInfo: null,
            notAvailableInfo: null,
            cols: 4,
          },
          {
            name: "Sonderabfall",
            label: "Sonderabfallannahmestelle",
            show: businessUnit.Soa,
            available: true,
            checkedInfo: null,
            notAvailableInfo: null,
            cols: 4,
          },
          {
            name: "KidsArea",
            label: "Kinderbereich (Planschbecken)",
            show: businessUnit.KidsArea,
            available: kidsAreaAvailable,
            checkedInfo: businessUnit.KidsAreaInfo,
            notAvailableInfo: businessUnit.KidsAreaNotAvailableInfo,
            cols: 12,
          },
        ]
          .filter((f) => f.show)
          .map((item, idx) => (
            <Grid item xs={12} md={item.cols} key={idx}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!item.available}
                    checked={values[item.name]}
                    onChange={(e) => {
                      handleChangeValue(item.name)(e);
                    }}
                    color="primary"
                  />
                }
                label={
                  item.label +
                  (!item.available && item.notAvailableInfo
                    ? ` (${item.notAvailableInfo})`
                    : "")
                }
              />
              {Boolean(values[item.name] && item.checkedInfo) && (
                <Box p={3} bgcolor={red[50]}>
                  <Typography
                    variant="body2"
                    component="div"
                    className={classes.mdText}
                  >
                    <ReactMarkdown
                      source={item.checkedInfo}
                      renderers={{
                        link: (props) => (
                          <a
                            href={props.href}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {props.children}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                </Box>
              )}
            </Grid>
          ))}
      </Grid>
      <Box pb={2} pt={5}>
        <Typography variant="h5">{businessUnit.WasteOriginTitle}</Typography>
      </Box>
      <Grid container spacing={2}>
        {businessUnit.InputFields && businessUnit.InputFields.Street && (
          <Grid item xs={8}>
            <TsTextField
              value={values.Street}
              handleChange={handleChangeValue}
              name="Street"
              label="Straße"
            />
          </Grid>
        )}

        {businessUnit.InputFields && businessUnit.InputFields.StreetNumber && (
          <Grid item xs={4} className={classes.streetNumber}>
            <TsTextField
              value={values.StreetNumber}
              handleChange={handleChangeValue}
              name="StreetNumber"
              label="Haus-Nr."
            />
          </Grid>
        )}
      </Grid>
      {businessUnit.InputFields && businessUnit.InputFields.Location && (
        <>
          {(!locations || !locations.length) && (
            <Grid container spacing={2}>
              <Grid item xs={4} className={classes.streetNumber}>
                <TsTextField
                  value={values.Zip}
                  handleChange={handleChangeValue}
                  name="Zip"
                  label="PLZ"
                />
              </Grid>
              <Grid item xs={8}>
                <TsTextField
                  value={values.City}
                  handleChange={handleChangeValue}
                  name="City"
                  label="Ort"
                />
              </Grid>
            </Grid>
          )}
          {Boolean(locations && locations.length) && (
            <Autocomplete
              noOptionsText="kein Eintrag gefunden"
              inputValue={locationInputValue}
              autoHighlight
              autoSelect
              options={locations}
              getOptionLabel={(location) => `${location.Zip} ${location.Name}`}
              onChange={(e, newValue) => {
                console.log("CHANGED LOCATION", newValue);
                handleChangeValue("location", newValue ? newValue.id : 0)(e);
                console.log(values);
              }}
              onInputChange={(event, newInputValue) => {
                setLocationInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={values.location}
                  id="no-autocomplete-input"
                  name="no-autocomplete-input"
                  autoComplete="nope"
                  label="PLZ"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          )}
        </>
      )}
      {Boolean(
        businessUnit.InputFields &&
          businessUnit.InputFields.AdditionalPersons &&
          additionalPersons.length
      ) && (
        <>
          <Box pb={2} pt={4}>
            <Typography variant="h5">
              {additionalPersons.length === 1
                ? "Begleitperson"
                : "Begleitpersonen"}
            </Typography>
          </Box>

          {additionalPersons.map((item, idx) => (
            <Box
              className={classes.additionalBox}
              px={2}
              py={{ xs: 1, sm: 0 }}
              mt={{ xs: 1, sm: 0 }}
              mb={{ xs: 2, sm: 2 }}
              key={idx}
              bgcolor={grey[100]}
            >
              <Grid container spacing={xs ? 0 : 2}>
                <Grid item xs={12} sm={6}>
                  <TsTextField
                    value={additionalPersons[idx].FirstName}
                    handleChange={() => (e) => {
                      const v = e.target.value;
                      setAdditionalPersons((p) => {
                        const newP = p.slice();
                        newP[idx].FirstName = v;
                        return newP;
                      });
                    }}
                    name={"firstName_" + idx}
                    label="Vorname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TsTextField
                    value={item.LastName}
                    handleChange={() => (e) => {
                      const v = e.target.value;
                      setAdditionalPersons((p) => {
                        const newP = p.slice();
                        newP[idx].LastName = v;
                        return newP;
                      });
                    }}
                    name={"lastName_" + idx}
                    label="Nachname"
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </>
      )}
      <Box mt={4}>
        <Typography variant="body2" className={classes.privacyText}>
          Welche Daten im Einzelnen gespeichert werden und wer Zugriff auf diese
          hat, erfahren Sie in unseren{" "}
          <a
            href={businessUnit.PrivacyExternalLink || "/datenschutz"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: businessUnit.ColorLinkText }}
          >
            Datenschutzhinweisen
          </a>
          . Ich stimme der Speicherung und Nutzung meiner Daten laut
          Datenschutzhinweisen zu und bin mit der Verarbeitung meiner Daten
          durch den Anbieter für die Bereitstellung dieses Dienstes
          einverstanden. Meine Einwilligungen kann ich jederzeit für die Zukunft
          widerrufen.
        </Typography>
      </Box>
      <Box mt={{ xs: 2, sm: 0 }} mb={{ xs: 1, sm: 0 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptPrivacy}
              onChange={handleChangePrivacy}
              color="primary"
            />
          }
          label={
            <Typography component="span" className={classes.privacyLabel}>
              Ich willige in diese Verarbeitung meiner Daten ein!
            </Typography>
          }
        />
      </Box>
      <Button
        disabled={isDisabled}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.btnBooking}
        onClick={onClick}
        style={
          isDisabled
            ? undefined
            : {
                backgroundColor: businessUnit.ColorButtonBackground,
                color: businessUnit.ColorButtonText || "#fff",
              }
        }
      >
        {btnText}
      </Button>
      {Boolean(onPrev) && (
        <Button
          variant="outlined"
          fullWidth
          className={classes.btnCancel}
          onClick={onPrev}
        >
          Zurück
        </Button>
      )}
    </>
  );
};

export default BookingForm;
