import React, { useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
  Container,
  Button,
  IconButton,
} from "@material-ui/core";
import { useEffect } from "react";
import API from "../utils/API";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/ErrorOutline";
import moment from "moment";
import Moment from "react-moment";
import { green, red, grey, amber } from "@material-ui/core/colors";
import BookingShopCart from "./BookingShopCart";
import BookingShopTotalPrice from "./BookingShopTotalPrice";
import BookingFormSummary from "./BookingFormSummary";
import BookingFormSelectionSummary from "./BookingFormSelectionSummary";
import clsx from "clsx";
import BookingPaymentSummary from "./BookingPaymentSummary";
import { useSelector } from "react-redux";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  icon: {
    width: 80,
    height: 80,
    borderRadius: 40,
    color: "#fff",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 10,
  },
  iconSuccess: {
    background: green[500],
  },
  iconWarning: {
    background: red[500],
  },
  smallWarningIcon: {
    color: red[500],
    marginRight: theme.spacing(2),
  },
  errorItem: {
    background: grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  topMenu: {
    position: "fixed",
    background: grey[900],
    top: 64,
    left: 0,
    right: 0,
    zIndex: 9,
    [theme.breakpoints.down("xs")]: {
      top: 56,
    },
  },
  topMenuBtn: {
    color: "#fff",
    marginLegt: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  checkInBtn: {
    color: "#fff",
    backgroundColor: green[500],
  },
  checkOutBtn: {
    color: "#fff",
    backgroundColor: red[500],
  },
  stepper: {
    background: grey[200],
    height: 54,
    borderRadius: 27,
    width: 150,
    paddingLeft: 2,
    paddingRight: 2,
    transition: "background-color 0.2s ease-in",
  },
}));

const ShopOrderDetails = ({ searchId, onCancel, onCheckIn, onCheckOut }) => {
  const classes = useStyles();
  const [booking, setBooking] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [reservedPlaces, setReservedPlaces] = useState(0);

  useEffect(() => {
    if (!searchId) {
      setBooking(null);
      return;
    }

    API.get("bookings/search-id/" + searchId + "/shop-order", {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((bookingsData) => {
        const bookingsResult = bookingsData.data ? bookingsData.data : null;
        // console.log(bookingsResult);
        setBooking(bookingsResult);
        if (
          bookingsResult.shop_order &&
          bookingsResult.shop_order.ReservedPlacesTotal
        ) {
          setReservedPlaces(bookingsResult.shop_order.ReservedPlacesTotal);
        }
      })
      .catch((error) => {
        setBooking(null);
        setNotFound(true);
      });
  }, [searchId]);

  if (notFound) {
    return (
      <Box p={8} textAlign="center">
        <Typography variant="h5">
          Es konnte keine Buchung gefunden werden
        </Typography>
        <Box mt={3}>
          <Button variant="outlined" onClick={onCancel}>
            zurück
          </Button>
        </Box>
      </Box>
    );
  }

  if (!businessUnit || !booking || !booking.slot) {
    return null;
  }

  // console.log(booking);

  const onMinus = () => {
    setReservedPlaces((state) => (state >= 1 ? state - 1 : 0));
  };

  const onPlus = () => {
    setReservedPlaces((state) => state + 1);
  };

  const isSameDay = booking.slot.Date === moment().format("YYYY-MM-DD");
  const isInTimeRange =
    booking.slot.From <= moment().format("HH:mm") &&
    booking.slot.To >= moment().format("HH:mm");

  const cart = booking.shop_order.ShopOrderItems.map((item) => ({
    quantity: item.Quantity,
    product: item,
  }));

  const errorWrongSlot =
    (!isSameDay || !isInTimeRange) &&
    "Das Zeitfenster ist im Moment nicht gültig";
  const errorAlreadyCheckedIn =
    booking.CheckedIn &&
    `Check-in bereits erfolgt (${moment(booking.CheckedInAt).format(
      "DD.MM.YYYY - HH:mm"
    )})`;
  const errorAlreadyCheckedOut =
    booking.CheckedOut &&
    `Check-out bereits erfolgt (${moment(booking.CheckedOutAt).format(
      "DD.MM.YYYY - HH:mm"
    )})`;
  const errorCancelled = booking.IsCancelled && "Es liegt eine Stornierung vor";
  const errorEC =
    booking.shop_order.PaymentKind === "ec" && "Betrag per EC-Karte kassieren!";

  const errorMessages = [
    errorWrongSlot,
    errorAlreadyCheckedIn,
    errorAlreadyCheckedOut,
    errorCancelled,
    errorEC,
  ];

  const isValid =
    !booking.CheckedIn &&
    !booking.CheckedOut &&
    !booking.IsCancelled &&
    isSameDay &&
    isInTimeRange &&
    !errorEC;

  return (
    <div className={classes.root}>
      <Box
        p={2}
        className={classes.topMenu}
        display="flex"
        justifyContent="center"
      >
        <Button className={classes.topMenuBtn} onClick={onCancel}>
          Abbrechen
        </Button>
        {Boolean(!booking.CheckedIn) && (
          <Button
            className={clsx(classes.topMenuBtn, classes.checkInBtn)}
            variant="contained"
            onClick={() => {
              onCheckIn(booking.uuid, reservedPlaces);
            }}
          >
            Check-In
          </Button>
        )}
        {Boolean(booking.CheckedIn && !booking.CheckedOut) && (
          <Button
            className={clsx(classes.topMenuBtn, classes.checkOutBtn)}
            variant="contained"
            onClick={() => {
              onCheckOut(booking.uuid);
            }}
          >
            Check-Out
          </Button>
        )}
      </Box>
      <Box textAlign="center" my={4}>
        {isValid && (
          <CheckIcon className={clsx(classes.icon, classes.iconSuccess)} />
        )}
        {!isValid && (
          <WarningIcon className={clsx(classes.icon, classes.iconWarning)} />
        )}
        <Typography variant="h2" gutterBottom>
          <Moment format="ddd, DD.MM.YYYY">{booking.slot.Date}</Moment>
        </Typography>
        <Typography variant="h3">
          <Moment format="HH:mm" parse="HH:mm:ss.SSS">
            {booking.slot.From}
          </Moment>{" "}
          -{" "}
          <Moment format="HH:mm" parse="HH:mm:ss.SSS">
            {booking.slot.To}
          </Moment>{" "}
          Uhr
        </Typography>
        {Boolean(booking.slot.Info) && (
          <Box my={2} p={2} bgcolor={amber[200]}>
            <Typography variant="p">
              {booking.slot.Info}
            </Typography>
          </Box>
        )}
        
      </Box>
      {Boolean(!booking.CheckedIn) && (
        <Box display="flex" justifyContent="center" my={4}>
          <Box
            display="flex"
            className={classes.stepper}
            justifyContent="space-between"
            alignItems="center"
            style={
              reservedPlaces <= 0
                ? undefined
                : {
                    backgroundColor: businessUnit.ColorHeaderBackground,
                    color: "#fff",
                  }
            }
          >
            <IconButton
              color="inherit"
              size="medium"
              disabled={reservedPlaces <= 0}
              onClick={() => {
                onMinus();
              }}
            >
              <MinusIcon fontSize="inherit" />
            </IconButton>
            <Typography variant="h6">{reservedPlaces}</Typography>
            <IconButton
              color="inherit"
              size="medium"
              onClick={() => {
                onPlus();
              }}
            >
              <PlusIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      )}

      {Boolean(errorMessages.filter((f) => f).length) && (
        <Box mb={4}>
          <Container maxWidth="sm">
            <Paper>
              <Box px={3} py={2}>
                {errorMessages
                  .filter((f) => f)
                  .map((item, idx) => (
                    <Box
                      key={idx}
                      className={classes.errorItem}
                      p={2}
                      my={1}
                      display="flex"
                    >
                      <WarningIcon className={classes.smallWarningIcon} />{" "}
                      <Typography>{item}</Typography>
                    </Box>
                  ))}
              </Box>
            </Paper>
          </Container>
        </Box>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={3}>
              <Box mb={2}>
                <BookingPaymentSummary booking={booking} />
              </Box>
              <Box mb={2}>
                <BookingShopCart cart={cart} />
              </Box>
              <BookingShopTotalPrice cart={cart} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Box p={3}>
              <Box mb={2}>
                <BookingFormSelectionSummary booking={booking} />
              </Box>
              <BookingFormSummary booking={booking} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShopOrderDetails;
