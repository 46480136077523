import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
  },
  divider: {
    backgroundColor: grey[400],
  },
  val: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
}));

const BookingFormSummary = ({ booking }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || !booking) {
    return null;
  }

  // console.log(booking);

  const name = `${booking.LastName}, ${booking.FirstName}`;
  const street = `${booking.Street} ${booking.StreetNumber}`;
  const city = `${booking.Zip} ${booking.City}`;
  const email = booking.Email;
  const phoneNumber = booking.PhoneNumber;
  const additionalPersons = booking.AdditionalPersons || [];

  // console.log(additionalPersons);

  const keyValues = [
    {
      title: "E-Mail",
      value: email,
    },
    {
      title: "Name",
      value: name,
    },
    {
      title: "Straße",
      value: street,
    },
    {
      title: "PLZ, Ort",
      value: city,
    },
  ];

  if (businessUnit.InputFields && businessUnit.InputFields.PhoneNumber) {
    keyValues.push({
      title: "Telefonnummer",
      value: phoneNumber,
    });
  }

  if (
    businessUnit.InputFields &&
    businessUnit.InputFields.AdditionalPersons &&
    additionalPersons &&
    additionalPersons.length
  ) {
    keyValues.push({
      title:
        additionalPersons.length === 1 ? "Begleitperson" : "Begleitpersonen",
      value: (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            paddingLeft: 16,
          }}
        >
          {additionalPersons.map((m, idx) => (
            <span style={{ textAlign: "right" }} key={idx}>
              {m.LastName}, {m.FirstName}
            </span>
          ))}
        </span>
      ),
    });
  }

  return (
    <>
      <Box className={classes.list}>
        {keyValues.map((item, idx, arr) => (
          <React.Fragment key={idx}>
            <Box p={2} display="flex">
              <Box
                width={120}
                flexShrink={0}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body1" component="span">
                  <strong>{item.title}</strong>
                </Typography>
              </Box>
              <Box flexGrow={1} textAlign="right">
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.val}
                >
                  {item.value}
                </Typography>
              </Box>
            </Box>
            {idx < arr.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default BookingFormSummary;
