import React, { useState, useEffect } from "react";
import { Typography, Box, makeStyles, Fade } from "@material-ui/core";
import Moment from "react-moment";
import moment from "moment";
import { amber } from "@material-ui/core/colors";
import ClockIcon from "mdi-react/ClockOutlineIcon";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexShrink: 0,
    },
  },
  txt: {
    flexGrow: 1,
  },
  box: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const CountDown = ({ start, handleTooLate }) => {
  const [diff, setDiff] = useState(null);
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  useEffect(() => {
    if (!businessUnit) {
      return;
    }
    const reservationSeconds =
      businessUnit && businessUnit.ReservationTimeSeconds
        ? businessUnit.ReservationTimeSeconds
        : 5 * 60;
    // console.log("CountDown", start);
    const s = moment(start);
    const end = s.add(reservationSeconds, "seconds");

    const intv = setInterval(() => {
      const d = moment(moment(end).diff(moment()));
      const isFuture = moment(end) > moment();
      if (!isFuture) {
        handleTooLate();
      } else {
        setDiff(d.format("m:ss"));
      }
    }, 1000);

    return () => {
      clearInterval(intv);
    };
  }, [businessUnit]);

  return (
    <>
      <Fade in={Boolean(diff)}>
        <Box
          mb={1}
          py={2}
          px={4}
          display="flex"
          alignItems="center"
          width="100%"
          bgcolor={amber[100]}
          className={classes.box}
        >
          <ClockIcon className={classes.icon} />

          <Typography variant="body1" className={classes.txt} component="div">
            Um Überschneidungen zu vermeiden ist Ihr Anmeldungsvorgang für{" "}
            <strong>{diff}</strong> reserviert
          </Typography>
        </Box>
      </Fade>
    </>
  );
};

export default CountDown;
