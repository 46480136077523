import React from "react";
import { useSelector } from "react-redux";
import BusinessUnitSelect from "./BusinessUnitSelect";
import Home from "./Home";

const HomeRoot = () => {
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit) {
    return null;
  }

  return (
    <>{businessUnit.isBusinessUnitSelect ? <BusinessUnitSelect /> : <Home />}</>
  );
};

export default HomeRoot;
