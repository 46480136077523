import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import TimesIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
  },
  divider: {
    backgroundColor: grey[400],
  },
  price: {
    fontWeight: 600,
    fontSize: 18,
    marginRight: theme.spacing(1),
  },
  currency: {
    fontSize: 18,
  },
  je: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  times: {
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const BookingShopCart = ({ cart, goToProducts }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || !cart || !cart.length) {
    return null;
  }

  return (
    <>
      <Box className={classes.list}>
        {cart
          .filter((f) => f.quantity > 0)
          .map((item, idx, arr) => (
            <React.Fragment key={idx}>
              <Box p={2} display="flex">
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Typography variant="body1" component="span">
                    {item.quantity}
                  </Typography>
                  <TimesIcon className={classes.times} />
                  <Typography variant="body1" component="span">
                    <strong>{item.product.Title}</strong>
                  </Typography>
                </Box>
                {Boolean(item.product.Price) && (
                  <Box width={120} flexShrink={0} textAlign="right">
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.je}
                  >
                    je
                  </Typography>
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.price}
                  >
                    {new Intl.NumberFormat("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item.product.Price)}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.currency}
                  >
                    €
                  </Typography>
                </Box>
                )}
                
              </Box>
              {idx < arr.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
        {Boolean(goToProducts) && (
          <>
            <Divider className={classes.divider} />
            <Box px={2} py={1} textAlign="right">
              <Button
                size="small"
                style={{ color: businessUnit.ColorLinkText }}
                onClick={goToProducts}
              >
                ändern
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BookingShopCart;
