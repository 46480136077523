import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import API from "../../utils/API";
import moment from "moment";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
  },
}));

const CurrentlyCheckedIn = () => {
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!auth || !auth.token) {
      setRedirect(true);
    }
    loadData();
  }, [auth]);

  useEffect(() => {
    if (businessUnit && businessUnit.isBusinessUnitSelect) {
      setRedirect(true);
    }
  }, [businessUnit]);

  const loadData = () => {
    const from = moment().format("YYYY-MM-DD");

    API.get("slots/bookings/" + from + "/" + from, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((slotsData) => {
        console.log(slotsData);
       
      })
      .catch((error) => {
        
      });
  }

  
  if (redirect) {
    return <Redirect to="/logout" />;
  }

  return (
    <>
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        Test
      </Container>
    </>
  );
};

export default CurrentlyCheckedIn;
