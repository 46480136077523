const initState = [];

const shopProductsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SHOP_PRODUCTS":
      return action.products;
    default:
      return state;
  }
};

export default shopProductsReducer;
