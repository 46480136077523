import API from "../../utils/API";
import moment from "moment";

export const addReservation = (slotId) => async (dispatch, getState) => {
  if (!slotId) {
    return;
  }

  const businessUnit = getState().businessUnit;
  const reservationSeconds =
    businessUnit && businessUnit.ReservationTimeSeconds
      ? businessUnit.ReservationTimeSeconds
      : 5 * 60;

  // console.log(businessUnit, reservationSeconds);
  const existing = getState().reservation.reservations[slotId];
  if (existing) {
    if (
      moment(existing.created_at).add(reservationSeconds, "seconds") > moment()
    ) {
      return;
    }
  }

  // localStorage aufräumen
  for (const sid of Object.keys(getState().reservation.reservations)) {
    if (
      moment(getState().reservation.reservations[sid].created_at).add(
        reservationSeconds,
        "seconds"
      ) < moment()
    ) {
      dispatch({
        type: "REMOVE_RESERVATION",
        slotId: sid,
      });
    }
  }

  const body = {
    slot: slotId,
  };
  API.post("reservations", body)
    .then((result) => {
      // console.log(result);
      const reservationResult = result.data ? result.data : null;
      // console.log(reservationResult);
      dispatch({
        type: "SET_RESERVATION",
        slotId,
        reservation: reservationResult,
      });
    })
    .catch((error) => {});
};

export const removeReservation = (slotId) => (dispatch) => {
  dispatch({
    type: "REMOVE_RESERVATION",
    slotId,
  });
};
