import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import useWindowSize from "../hooks/useWindowSize";
import { Box } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

const Chart = ({ slots, sumDays }) => {
  const size = useWindowSize();
  const businessUnit = useSelector((state) => state.businessUnit);

  // console.log(slots);
  if (!businessUnit || !businessUnit.Stats) {
    return null;
  }

  let data;

  if (!sumDays) {
    data = slots.map((slot) => {
      const item = {
        name:
          moment(slot.Date).format("ddd, DD.MM.YY") +
          " " +
          moment(slot.From, "HH:mm.ss.SSSS").format("H:mm") +
          " - " +
          moment(slot.To, "HH:mm.ss.SSSS").format("H:mm"),
      };

      if (businessUnit.Stats.BookingsGraph) {
        item["Buchungen"] = slot.bookings.filter((f) => !f.IsCancelled).length;
      }

      if (businessUnit.Stats.SeatsGraph) {
        item["Plätze"] = slot.bookings
          .filter((f) => !f.IsCancelled)
          .map((m) => (!m.shop_order ? 1 : m.shop_order.ReservedPlacesTotal))
          .reduce((a, b) => a + b, 0);
      }

      if (businessUnit.Stats.CheckedInBookingsGraph) {
        item["Check-In Buchungen"] = slot.bookings.filter(
          (f) => !f.IsCancelled && f.CheckedIn
        ).length;
      }

      if (businessUnit.Stats.CheckedInSeatsGraph) {
        item["Check-In Plätze"] = slot.bookings
          .filter((f) => !f.IsCancelled && f.CheckedIn)
          .map((m) => (!m.shop_order ? 1 : m.shop_order.ReservedPlacesTotal))
          .reduce((a, b) => a + b, 0);
      }

      if (businessUnit.Stats.WshGraph) {
        item["WSH"] = slot.bookings.filter(
          (f) => f.Wsh && !f.IsCancelled
        ).length;
      }

      if (businessUnit.Stats.SoaGraph) {
        item["SOA"] = slot.bookings.filter(
          (f) => f.Sonderabfall && !f.IsCancelled
        ).length;
      }

      if (businessUnit.Stats.KidsAreaGraph) {
        item["Kinderbereich"] = slot.bookings.filter(
          (f) => f.KidsArea && !f.IsCancelled
        ).length;
      }

      return item;
    });
  }

  if (sumDays) {
    const days = new Set(slots.map((m) => m.Date));

    data = [...days].map((day) => {
      const item = {
        name: moment(day).format("ddd, DD.MM.YY"),
      };

      if (businessUnit.Stats.BookingsGraph) {
        item["Buchungen"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev + cur.bookings.filter((b) => !b.IsCancelled).length,
            0
          );
      }

      if (businessUnit.Stats.SeatsGraph) {
        item["Plätze"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev +
              cur.bookings
                .filter((f) => !f.IsCancelled)
                .map((m) =>
                  !m.shop_order ? 1 : m.shop_order.ReservedPlacesTotal
                )
                .reduce((a, b) => a + b, 0),
            0
          );
      }

      if (businessUnit.Stats.CheckedInBookingsGraph) {
        item["Check-In Buchungen"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev +
              cur.bookings.filter((b) => !b.IsCancelled && b.CheckedIn).length,
            0
          );
      }

      if (businessUnit.Stats.CheckedInSeatsGraph) {
        item["Check-In Plätze"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev +
              cur.bookings
                .filter((f) => !f.IsCancelled && f.CheckedIn)
                .map((m) =>
                  !m.shop_order ? 1 : m.shop_order.ReservedPlacesTotal
                )
                .reduce((a, b) => a + b, 0),
            0
          );
      }

      if (businessUnit.Stats.WshGraph) {
        item["WSH"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev + cur.bookings.filter((f) => f.Wsh && !f.IsCancelled).length,
            0
          );
      }

      if (businessUnit.Stats.SoaGraph) {
        item["SOA"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev +
              cur.bookings.filter((f) => f.Sonderabfall && !f.IsCancelled)
                .length,
            0
          );
      }

      if (businessUnit.Stats.KidsAreaGraph) {
        item["Kinderbereich"] = slots
          .filter((f) => f.Date === day)
          .reduce(
            (prev, cur) =>
              prev +
              cur.bookings.filter((f) => f.KidsArea && !f.IsCancelled).length,
            0
          );
      }

      return item;
    });
  }

  return (
    <Box pb={3} display="flex" justifyContent="center">
      <LineChart
        width={size[0] - 100}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />

        {businessUnit.Stats.SeatsGraph && (
          <Line
            type="monotone"
            dataKey="Plätze"
            stroke={businessUnit.Stats.SeatsGraphColor}
            activeDot={{ r: 8 }}
          />
        )}

        {businessUnit.Stats.BookingsGraph && (
          <Line
            type="monotone"
            dataKey="Buchungen"
            stroke={businessUnit.Stats.BookingsGraphColor}
            activeDot={businessUnit.Stats.SeatsGraph ? undefined : { r: 8 }}
          />
        )}

        {businessUnit.Stats.CheckedInSeatsGraph && (
          <Line
            type="monotone"
            dataKey="Check-In Plätze"
            stroke={businessUnit.Stats.CheckedInSeatsGraphColor}
          />
        )}

        {businessUnit.Stats.CheckedInBookingsGraph && (
          <Line
            type="monotone"
            dataKey="Check-In Buchungen"
            stroke={businessUnit.Stats.CheckedInBookingsGraphColor}
          />
        )}

        {businessUnit.Stats.WshGraph && (
          <Line
            type="monotone"
            dataKey="WSH"
            stroke={businessUnit.Stats.WshGraphColor}
          />
        )}

        {businessUnit.Stats.SoaGraph && (
          <Line
            type="monotone"
            dataKey="SOA"
            stroke={businessUnit.Stats.SoaGraphColor}
          />
        )}

        {businessUnit.Stats.KidsAreaGraph && (
          <Line
            type="monotone"
            dataKey="Kinderbereich"
            stroke={businessUnit.Stats.KidsAreaGraphColor}
          />
        )}
      </LineChart>
    </Box>
  );
};

export default Chart;
