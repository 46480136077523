import React from "react";
import { Typography, Box, Grid, Divider, makeStyles } from "@material-ui/core";
import Moment from "react-moment";
import { blueGrey } from "@material-ui/core/colors";
import SlotBookingItem from "./SlotBookingItem";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  timeTitle: {
    position: "sticky",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    marginTop: theme.spacing(-4),
    background: blueGrey[100],
    top: 149,
    zIndex: 1,
  },
}));

const SlotBookings = ({
  slot,
  search,
  onlyPhoneBooking,
  onCheckIn,
  onCheckOut,
  onSave,
  showSearch,
}) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || !businessUnit.Stats) {
    return null;
  }

  let bookings = slot.bookings;
  if (onlyPhoneBooking) {
    bookings = bookings.filter(
      (f) => f.Email.toLowerCase() === businessUnit.Stats.OnlyPhoneFilterEmail
    );
  }

  if (showSearch && search.length < 3 && !onlyPhoneBooking) {
    bookings = [];
  }

  if (showSearch && search) {
    bookings = bookings.filter((f) => {
      if (
        f.NumberPlate.replace(/\(.+?\)/g, "")
          .replace(/[^a-z0-9+]+/gi, " ")
          .replace(/\s/g, "")
          .toUpperCase()
          .indexOf(search.replace(/\s/g, "").toUpperCase()) !== -1
      ) {
        return true;
      }
      if (f.FirstName.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
        return true;
      }
      if (f.LastName.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
        return true;
      }
    });
  }

  return (
    <>
      <Box py={1} px={4} className={classes.timeTitle}>
        <Typography variant="h4" component="div">
          <Moment format="H:mm" parse="HH:mm:ss.SSS">
            {slot.From}
          </Moment>{" "}
          -{" "}
          <Moment format="H:mm" parse="HH:mm:ss.SSS">
            {slot.To}
          </Moment>{" "}
          Uhr
        </Typography>
      </Box>
      <Box pb={10}>
        <Box mt={2}>
          {bookings.map((b, idx) => (
            <SlotBookingItem
              item={b}
              key={idx}
              onCheckIn={onCheckIn}
              onCheckOut={onCheckOut}
              onSave={onSave}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SlotBookings;
