import React, { useEffect, useState } from "react";
import BellIcon from "mdi-react/BellOutlineIcon";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import {
  makeStyles,
  IconButton,
  Button,
  Popover,
  Typography,
  Box,
  Badge,
} from "@material-ui/core";
import useScreensize from "../hooks/useScreensize";
import { Link } from "react-router-dom";
import API from "../utils/API";
import { grey, red } from "@material-ui/core/colors";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  btnNewsIcon: {
    marginLeft: theme.spacing(1),
  },
  newsItemContainer: {
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  newsItemsWrapper: {
    maxWidth: 400,
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
  },
  title: {
    fontWeight: 700,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    display: "block",
    color: "#000",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
    lineHeight: 1.3,
  },
  popover: {
    marginTop: theme.spacing(2.25),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: red[500],
    color: "#fff",
  },
}))(Badge);

const News = () => {
  const classes = useStyles();
  const { xs, sm, md, lg, xl } = useScreensize();
  const [anchorEl, setAnchorEl] = useState(null);
  const [news, setNews] = useState([]);
  const businessUnit = useSelector((state) => state.businessUnit);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    API.get("articles")
      .then((newsData) => {
        // console.log(newsData);
        let newsResult = newsData.data ? newsData.data : [];
        newsResult = newsResult.sort((a, b) => {
          const aD = a.Override_updated_at || a.updated_at;
          const bD = b.Override_updated_at || b.updated_at;
          return aD > bD ? -1 : 1;
        });
        setNews(newsResult);
      })
      .catch((error) => {
        setNews([]);
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!businessUnit) {
    return null;
  }

  return (
    <>
      {((!lg && !xl) || (auth && auth.token)) && (
        <IconButton
          color="primary"
          className={classes.newsIcon}
          onClick={handleClick}
        >
          <StyledBadge badgeContent={news.length} color="primary">
            <BellIcon />
          </StyledBadge>
        </IconButton>
      )}
      {(lg || xl) && (!auth || (auth && !auth.token)) && (
        <Button
          color="primary"
          className={classes.btnNews}
          onClick={handleClick}
          style={{ color: businessUnit.ColorMenuItemEmphasizeText }}
        >
          Wichtige Nachrichten{" "}
          <StyledBadge badgeContent={news.length} color="primary">
            <BellIcon className={classes.btnNewsIcon} />
          </StyledBadge>
        </Button>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.popover}
      >
        <div className={classes.newsItemContainer}>
          <Box py={1.5} className={classes.newsItemsWrapper}>
            {news.map((n) => (
              <Box py={1.5} px={3} key={n.id}>
                <Typography variant="caption" color="textSecondary">
                  <Moment locale="de" format="ddd, D. MMMM YYYY">
                    {n.Override_updated_at || n.updated_at}
                  </Moment>
                </Typography>
                <Typography
                  variant="h2"
                  className={classes.title}
                  component={Link}
                  to={"/news/" + n.id}
                  onClick={handleClose}
                >
                  {n.Title}
                </Typography>
                {n.Subtitle && (
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    className={classes.subtitle}
                  >
                    {n.Subtitle}
                  </Typography>
                )}
                <Typography variant="body2">
                  {n.Teaser}{" "}
                  <Link to={"/news/" + n.id} onClick={handleClose}>
                    ...weiterlesen
                  </Link>
                </Typography>
              </Box>
            ))}
          </Box>
        </div>
      </Popover>
    </>
  );
};

export default News;
