import React from "react";
import { Helmet } from "react-helmet";

const ZaSeo = ({
  title = "Terminanmeldung Wertstoffhof",
  description = "Buchungsportal für Ihre Anlieferung im Wertstoffhof",
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </>
  );
};

export default ZaSeo;
