import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Radio,
  Fade,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import PayDirekt from "../assets/giropay-paydirekt.png";
import Visa from "../assets/visa.png";
import MasterCard from "../assets/mastercard.png";
import AmericanExpress from "../assets/amex.jpg";
import GiroPay from "../assets/giropay.png";
import { PayPalButton } from "react-paypal-button-v2";
import PayPalLogo from "../assets/paypal.png";
import ECLogo from "../assets/ec.png";
import { CardElement } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  divider: {
    backgroundColor: grey[400],
  },
  val: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  cardLogo: {
    height: 20,
    width: "auto",
    marginLeft: theme.spacing(0.5),
  },
  titleLogo: {
    height: 25,
    width: "auto",
  },
  paymentKindRow: {
    cursor: "pointer",
    transition: "all 0.2s ease-in",
    "&:hover": {
      background: grey[50],
    },
  },
  activePaymentKindRow: {
    "&:hover": {
      background: grey[100],
    },
  },
  activePaymentKindWrapper: {
    background: grey[100],
  },
  paypalWaiting: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    background: "rgba(255,255,255,0.7)",
  },
}));

const BookingPaymentKinds = ({
  paymentKind,
  setPaymentKind,
  createPayPalOrder,
  setCardInputComplete,
  bookingIdForPayPalRedirect,
  onCompleted,
  setIsBooking,
  acceptRevocation,
  revocationNeeded,
  acceptAgb,
  agbNeeded,
  onPayOne,
  sum,
}) => {
  const classes = useStyles();
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [paypalWaiting, setPaypalWaiting] = useState(false);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [paymentKindOptions, setPaymentKindOptions] = useState([]);
  const [paypalDisabledFundings, setPaypalDisabledFundings] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!businessUnit || !businessUnit.Payment) {
      return;
    }

    const options = [];

    if (businessUnit.Payment.ECLoggedIn && auth && auth.token) {
      options.push({
        title: "EC-Karte",
        value: "ec",
        logos: [
          {
            src: ECLogo,
            alt: "EC-Karte",
          },
        ],
      });
    }

    if (businessUnit.Payment.GiroSolution) {
      options.push({
        title: "Online bezahlen",
        value: "giroSolution",
        logos: [
          {
            src: PayPalLogo,
            alt: "PayPal",
          },
          {
            src: Visa,
            alt: "Visa",
          },
          {
            src: MasterCard,
            alt: "Mastercard",
          },
          {
            src: GiroPay,
            alt: "GIROPAY",
          },
          {
            src: PayDirekt,
            alt: "PayDirekt",
          },
        ],
      });
    }

    if (businessUnit.Payment.PayPal) {
      options.push({
        title: "PayPal",
        value: "paypal",
        logos: [
          {
            src: PayPalLogo,
            alt: "PayPal",
          },
        ],
      });
    }

    if (businessUnit.Payment.StripeCreditCard && sum >= 1) {
      options.push({
        title: "Kreditkarte",
        value: "creditCard",
        logos: [
          {
            src: Visa,
            alt: "Visa",
          },
          {
            src: MasterCard,
            alt: "Mastercard",
          },
          {
            src: AmericanExpress,
            alt: "American Express",
          },
        ],
      });
    }

    if (businessUnit.Payment.StripeGiroPay && sum >= 1) {
      options.push({
        title: "Online-Überweisung",
        value: "giroPay",
        logos: [
          {
            src: GiroPay,
            alt: "GIROPAY",
          },
        ],
      });
    }

    if (businessUnit.Payment.PayOne) {
      options.push({
        title: "PayOne",
        value: "payOne",
        logos: [
          {
            src: GiroPay,
            alt: "GIROPAY",
          },
        ],
      });
    }

    if (businessUnit.Payment.EC) {
      options.push({
        title: "EC-Karte (vor Ort)",
        value: "ec",
        logos: [
          {
            src: ECLogo,
            alt: "EC-Karte",
          },
        ],
      });
    }

    setPaymentKindOptions(options);

    // Disabled Funding
    const disabled = [
      "credit",
      "bancontact",
      "venmo",
      "sepa",
      "eps",
      "ideal",
      "mybank",
      "p24",
    ];
    if (!businessUnit.Payment.PayPalCreditCard) {
      disabled.push("card");
    }
    if (!businessUnit.Payment.PayPalGiroPay) {
      disabled.push("giropay");
    }
    if (!businessUnit.Payment.PayPalSofort) {
      disabled.push("sofort");
    }
    setPaypalDisabledFundings(disabled.join(","));

    // Default
    if (options && options.length) {
      setPaymentKind(options[0].value);
    }
    /*
    if (
      !businessUnit.Payment.StripeCreditCard &&
      !businessUnit.Payment.StripeGiroPay &&
      !businessUnit.Payment.EC &&
      !businessUnit.Payment.ECLoggedIn
    ) {
      setPaymentKind("paypal");
    }

    if (!businessUnit.Payment.PayPal) {
      if (
        !businessUnit.Payment.StripeCreditCard &&
        businessUnit.Payment.StripeGiroPay
      ) {
        setPaymentKind("giroPay");
      }
      if (
        !businessUnit.Payment.StripeGiroPay &&
        businessUnit.Payment.StripeCreditCard
      ) {
        setPaymentKind("creditCard");
      }
    }
    */
  }, [businessUnit]);

  if (!businessUnit) {
    return null;
  }

  return (
    <>
      <Box className={classes.list}>
        {paymentKindOptions.map((item, idx, arr) => (
          <React.Fragment key={idx}>
            <Box
              className={clsx(
                item.value === paymentKind && classes.activePaymentKindWrapper
              )}
            >
              <Box
                p={2}
                display="flex"
                className={clsx(
                  classes.paymentKindRow,
                  item.value === paymentKind && classes.activePaymentKindRow
                )}
                onClick={() => {
                  setPaymentKind(item.value);
                }}
              >
                <Box
                  width={50}
                  flexShrink={0}
                  display="flex"
                  alignItems="center"
                >
                  <Radio
                    color="primary"
                    checked={paymentKind === item.value}
                    onChange={() => {
                      setPaymentKind(item.value);
                    }}
                    value={item.value}
                  />
                </Box>
                <Box
                  width={120}
                  flexGrow={1}
                  flexShrink={0}
                  display="flex"
                  alignItems="center"
                >
                  {item.titleLogo && (
                    <img
                      src={item.titleLogo}
                      alt={item.title}
                      className={classes.titleLogo}
                    />
                  )}
                  {!item.titleLogo && (
                    <Typography variant="body1" component="span">
                      <strong>{item.title}</strong>
                    </Typography>
                  )}
                </Box>
                {Boolean(item.logos && item.logos.length) && (
                  <Box
                    flexGrow={1}
                    textAlign="right"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {item.logos.map((logo, logoIdx) => (
                      <img
                        src={logo.src}
                        alt={logo.alt}
                        className={classes.cardLogo}
                        key={logoIdx}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              {item.value === "creditCard" && paymentKind === "creditCard" && (
                <Box pt={1} px={2} pb={2}>
                  <CardElement
                    hidePostalCode={true}
                    onChange={(e) => {
                      // console.log(e);
                      setCardInputComplete(e.complete);
                    }}
                  />
                </Box>
              )}
              {item.value === "payOne" && paymentKind === "payOne" && (
                <Box pt={1} px={2} pb={2}>
                  <Button variant="contained" fullWidth onClick={onPayOne}>
                    PayOne
                  </Button>
                </Box>
              )}
              {item.value === "paypal" && paymentKind === "paypal" && (
                <>
                  {revocationNeeded && !acceptRevocation && (
                    <Box px={2} pb={2}>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        align="left"
                        gutterBottom
                      >
                        Bitte beachten Sie die Hinweise zu{" "}
                        {businessUnit.RevocationRightTitle} bevor Sie fortfahren
                        können!
                      </Typography>
                    </Box>
                  )}
                  {agbNeeded && !acceptAgb && (
                    <Box px={2} pb={2}>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        align="left"
                        gutterBottom
                      >
                        Bitte beachten Sie die Hinweise zu{" "}
                        {businessUnit.AgbTitle} bevor Sie fortfahren können!
                      </Typography>
                    </Box>
                  )}
                  {(!revocationNeeded || acceptRevocation) &&
                    (!agbNeeded || acceptAgb) && (
                      <Box px={2} pb={2} position="relative">
                        {(!paypalLoaded || paypalWaiting) && (
                          <div className={classes.paypalWaiting}>
                            <CircularProgress></CircularProgress>
                          </div>
                        )}
                        <Box py={2}>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                            align="center"
                            gutterBottom
                          >
                            {Boolean(
                              businessUnit.Payment.PayPalGiroPay ||
                                businessUnit.Payment.PayPalSofort
                            ) &&
                              "Bitte verwenden Sie einen der folgenden Buttons um den Kauf abzuschließen"}
                            {Boolean(
                              !businessUnit.Payment.PayPalGiroPay &&
                                !businessUnit.Payment.PayPalSofort
                            ) &&
                              "Bitte verwenden Sie den PayPal-Button um den Kauf abzuschließen"}
                          </Typography>
                        </Box>
                        <PayPalButton
                          onButtonReady={() => {
                            setPaypalLoaded(true);
                          }}
                          shippingPreference="NO_SHIPPING"
                          // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                          onSuccess={(details, data) => {
                            // console.log(data);
                            setPaypalWaiting(false);
                            onCompleted(bookingIdForPayPalRedirect);
                            // OPTIONAL: Call your server to save the transaction
                          }}
                          onError={(error) => {
                            setPaypalWaiting(false);
                            setIsBooking(false);
                            // console.log("ERROR", error);
                          }}
                          onCancel={() => {
                            setPaypalWaiting(false);
                            setIsBooking(false);
                            // console.log("CANCELLED");
                          }}
                          createOrder={(data, actions) => {
                            setPaypalWaiting(true);
                            return createPayPalOrder(data, actions);
                          }}
                          options={{
                            disableFunding: paypalDisabledFundings,
                            currency: "EUR",
                            clientId: businessUnit.Payment.PayPalClientId,
                          }}
                        />
                      </Box>
                    )}
                </>
              )}
            </Box>
            {idx < arr.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default BookingPaymentKinds;
