import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
  Link,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  bodyText: {
    lineHeight: 1.4,
  },
}));

const Imprint = () => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  // console.log(article);

  if (!businessUnit) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography
                variant="body1"
                component="div"
                className={classes.bodyText}
              >
                {businessUnit.ImprintExternalLink && (
                  <Link
                    component="a"
                    href={businessUnit.ImprintExternalLink}
                    target="_blank"
                  >
                    {businessUnit.ImprintExternalLink}
                  </Link>
                )}

                {!businessUnit.ImprintExternalLink && (
                  <ReactMarkdown source={businessUnit.Imprint} />
                )}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Imprint;
