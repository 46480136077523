import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import useValues from "@bit/wamedia.tasso.use-values";
import TextField from "@material-ui/core/TextField";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";
import API from "../../utils/API";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import * as authActions from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    marginTop: theme.spacing(1),
  },
}));

const initialValues = {
  identifier: "",
  password: "",
};

const Login = () => {
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const classes = useStyles();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // console.log("AUTH CHANGED", auth);
    if (auth && auth.token) {
      setRedirect(true);
    }
  }, [auth]);

  const handleLogin = (e) => {
    // console.log("SUBMIT");
    e.preventDefault();
    setError(false);
    API.post("auth/local", values)
      .then((authData) => {
        const data = authData.data || null;
        if (data) {
          const token = data.jwt;
          const expire = moment(
            JSON.parse(atob(token.split(".")[1])).exp * 1000
          ).format();
          // console.log(expire);
          dispatch(authActions.setToken(token));
        }
        // console.log(authData);
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setError(true);
      });
  };

  if (redirect) {
    return <Redirect to="/reservierungen" />;
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 3 }}>
          <Paper>
            <Box p={{ xs: 4, md: 5 }}>
              <Typography variant="h1" align="center" gutterBottom>
                Login
              </Typography>
              {error && (
                <Box py={2}>
                  <Alert severity="error">Login Fehler</Alert>
                </Box>
              )}
              <form
                onSubmit={(e) => {
                  handleLogin(e);
                }}
              >
                <TsTextField
                  value={values.identifier}
                  handleChange={handleChangeValue}
                  name="identifier"
                  label="Benutzername"
                />
                <TextField
                  fullWidth
                  label="Passwort"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  margin="dense"
                  onChange={(e) => {
                    handleChangeValue("password")(e);
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  className={classes.btn}
                >
                  Login
                </Button>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Login;
