import React from "react";
import { Typography, Box, makeStyles, Paper, Link } from "@material-ui/core";
import useScreensize from "../../hooks/useScreensize";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { blueGrey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.secondary.main,
    backgroundSize: "cover",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  arrow: {
    width: 36,
    height: 36,
  },
  wrapper: {
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
  },
  introText: {
    opacity: 0.85,
  },
  optionLink: {
    display: "block",
    marginBottom: theme.spacing(2),
    textDecoration: "none !important",
    transition: "transform 0.1s ease-in",
    "&:hover": {
      transform: "scale(1.048) translateZ(0)",
    },
  },
  arrowBtn: {
    color: blueGrey[100],
    width: 50,
    height: 50,
  },
}));

const BusinessUnitSelect = () => {
  const classes = useStyles();
  const { xs } = useScreensize();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit) {
    return null;
  }

  // console.log(businessUnit);

  return (
    <>
      <Box
        className={classes.header}
        style={{ background: businessUnit.ColorHeaderBackground }}
        pt={4}
        pb={6}
        px={0.5}
        color="white"
      >
        <Box px={2} pb={{ md: 2 }} pt={{ md: 2 }}>
          <Typography
            variant="h1"
            align="center"
            gutterBottom
            style={{ color: businessUnit.ColorHeaderText }}
          >
            {businessUnit.Title}
          </Typography>
        </Box>
        {Boolean(businessUnit.IntroText) && (
          <Box px={2} pb={{ md: 4 }} className={classes.wrapper}>
            <Typography
              variant={xs ? "body2" : "body1"}
              align="center"
              component="div"
              className={classes.introText}
              style={{ color: businessUnit.ColorHeaderText }}
            >
              <ReactMarkdown source={businessUnit.IntroText} />
            </Typography>
          </Box>
        )}
      </Box>
      <Box px={2} pt={0} pb={4} mt={-6} className={classes.wrapper}>
        {businessUnit.Options.map((option, idx) => (
          <Box
            key={idx}
            className={classes.optionLink}
            component={Link}
            href={option.Url}
          >
            <Paper>
              <Box p={4} display="flex" alignItems="center">
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <Typography variant="h3">{option.Title}</Typography>
                  <Typography variant="body2" component="div">
                    <ReactMarkdown source={option.Description} />
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" pl={4}>
                  <ChevronRightIcon className={classes.arrowBtn} />
                </Box>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default BusinessUnitSelect;
