const initState = {
  hidden: false,
  updatedAt: null,
  content: ""
};

const breakingNewsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_CLOSE":
      return {
        ...state,
        hidden: true
      };
    case "SET_BREAKING_NEWS":
      return {
        ...state,
        hidden: false,
        updatedAt: action.date,
        content: action.content
      };
    default:
      return state;
  }
};

export default breakingNewsReducer;
