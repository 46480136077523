import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  bodyText: {
    lineHeight: 1.4,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

const PaymentError = ({ match }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || businessUnit.isBusinessUnitSelect) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                Die Zahlung war nicht erfolgreich
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.bodyText}
              >
                Leider konnte Ihre Buchung nicht abgeschlossen werden, da bei der Bezahlung ein Fehler aufgetreten ist.
              </Typography>
              <Typography variant="body2">
                  <Link to={"/"}>
                    Zurück zur Startseite
                  </Link>
                </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default PaymentError;
