import React, { useState } from "react";
import {
  Typography,
  Paper,
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Chip,
} from "@material-ui/core";
import { blueGrey, red } from "@material-ui/core/colors";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import Moment from "react-moment";
import clsx from "clsx";
import Booking from "./Booking";
import { useSelector } from "react-redux";
import moment from "moment";
import SlotSeatsEdit from "./SlotSeatsEdit";
import SlotEdit from "./SlotEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    opacity: 1,
    transition: "opacity 0.2s ease-in",
    cursor: "pointer",
  },
  rootLoading: {
    pointerEvents: "none",
  },
  rootDisabled: {
    cursor: "not-allowed",
  },
  free: {
    background: blueGrey[50],
    borderRadius: theme.shape.borderRadius,
  },
  freeLabel: {
    color: blueGrey[600],
    fontSize: 12,
  },
  freeValue: {
    color: blueGrey[400],
    fontSize: 30,
  },
  arrowBtn: {
    color: blueGrey[100],
  },
  timeLabel: {
    marginRight: "-20px",
  },
  time: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
    },
  },
  disabledBox: {
    opacity: 0.3,
  },
  chip: {
    backgroundColor: red[500],
    color: "#fff",
    marginLeft: theme.spacing(0)
  },
  infoBadge: {
    marginBottom: theme.spacing(1),
  },
  infoBadgeText: {
    fontWeight: "bold",
    borderBottom: "3px solid transparent",
    paddingBottom: theme.spacing(0.5)
  }
}));

const Slot = ({ slot, loading, reloadSlots }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const auth = useSelector((state) => state.auth);
  const businessUnit = useSelector((state) => state.businessUnit);

  const handleOpen = () => {
    if (loading) {
      return;
    }
    if (
      slot.Date === moment().format("YYYY-MM-DD") &&
      slot.To < moment().format("HH:mm:ss.SSS")
    ) {
      return;
    }
    if (slot.AvailableSeats < 1 && (!auth || !auth.token)) {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = (e) => {
    e.stopPropagation();
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    reloadSlots();
  };

  if (!businessUnit) {
    return null;
  }

  const slotInPast =
    slot.Date === moment().format("YYYY-MM-DD") &&
    slot.To < moment().format("HH:mm:ss.SSS");

  const disabled = slot.AvailableSeats < 1 && (!auth || !auth.token);

  return (
    <>
      <Paper
        className={clsx(
          classes.root,
          loading && classes.rootLoading,
          (disabled || slotInPast) && classes.rootDisabled
        )}
        onClick={handleOpen}
      >
        <Box
          p={2}
          display="flex"
          className={clsx((disabled || slotInPast) && classes.disabledBox)}
        >
          <Box
            width={70}
            height={70}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.free}
            onClick={
              auth &&
              auth.token &&
              businessUnit.Permissions &&
              (businessUnit.Permissions.EditSlot || businessUnit.Permissions.EditSeats)
                ? handleOpenEdit
                : undefined
            }
          >
            <Typography variant="caption" className={classes.freeLabel}>
              Plätze
            </Typography>
            <Typography
              variant="h3"
              component="div"
              className={classes.freeValue}
            >
              {slot.AvailableSeats > 0 ? slot.AvailableSeats : 0}
            </Typography>
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pl={2}
          >
            {Boolean(slot.Info) && (
              <Box className={classes.infoBadge}>
                <span className={classes.infoBadgeText} style={!businessUnit.ColorSlotInfoChip || !businessUnit.ColorSlotInfoChipBackground ? undefined : {color: businessUnit.ColorSlotInfoChip, borderColor: businessUnit.ColorSlotInfoChipBackground}}>
                  {slot.Info}
                </span>
              </Box>
            )}
            <Typography variant="caption" className={classes.timeLabel}>
              {businessUnit.SlotTitle || "Zeitfenster für die Anfahrt"}
            </Typography>
            <Typography variant="h5" className={classes.time}>
              <Moment format="H:mm" parse="HH:mm:ss.SSS">
                {slot.From}
              </Moment>{" "}
              -{" "}
              <Moment format="H:mm" parse="HH:mm:ss.SSS">
                {slot.To}
              </Moment>{" "}
              Uhr
            </Typography>
            
          </Box>
          
          {disabled && (
            <Box display="flex" alignItems="center">
              <Chip label="ausgebucht" className={classes.chip} />
            </Box>
          )}
          {!disabled && (
            <Box display="flex" alignItems="center">
              <ChevronRightIcon className={classes.arrowBtn} />
            </Box>
          )}
        </Box>
      </Paper>
      <Booking slot={slot} handleClose={handleClose} open={open} />
      {auth &&
        auth.token &&
        businessUnit.Permissions &&
        businessUnit.Permissions.EditSlot && (
          <SlotEdit
            slot={slot}
            handleClose={handleCloseEdit}
            open={openEdit}
          />
        )}
      {auth &&
        auth.token &&
        businessUnit.Permissions &&
        (!businessUnit.Permissions.EditSlot && businessUnit.Permissions.EditSeats) && (
          <SlotSeatsEdit
            slot={slot}
            handleClose={handleCloseEdit}
            open={openEdit}
          />
        )}
    </>
  );
};

export default Slot;
