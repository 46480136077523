import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Box,
  makeStyles,
} from "@material-ui/core";
import API from "../utils/API";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { red } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  item: {
    borderBottom: "1px solid #eee",
  },
  cancelled: {
    background: red[50],
  },
}));

const SlotBookingInfo = ({ numberPlate, handleClose }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    API.get("bookings/number-plate/" + numberPlate, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((bookingsData) => {
        const bookingsResult = bookingsData.data ? bookingsData.data : [];
        setBookings(
          bookingsResult.sort((a, b) => {
            if (a.slot.Date === b.slot.Date) {
              return a.slot.From < b.slot.From ? -1 : 1;
            }
            return a.slot.Date < b.slot.Date ? -1 : 1;
          })
        );
      })
      .catch((error) => {
        setBookings([]);
      });
  }, []);

  return (
    <>
      <DialogTitle>Buchungsübersicht für {numberPlate}</DialogTitle>
      <DialogContent>
        <Box p={4}>
          <Grid container spacing={4}>
            {bookings.map((b, idx) => (
              <React.Fragment key={idx}>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.item,
                    b.IsCancelled && classes.cancelled
                  )}
                >
                  <strong>
                    <Moment locale="de" format="DD.MM.YYYY">
                      {b.slot.Date}
                    </Moment>
                  </strong>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.item,
                    b.IsCancelled && classes.cancelled
                  )}
                >
                  <Moment format="HH:mm" parse="HH:mm:ss.SSS">
                    {b.slot.From}
                  </Moment>{" "}
                  -{" "}
                  <Moment format="HH:mm" parse="HH:mm:ss.SSS">
                    {b.slot.To}
                  </Moment>{" "}
                  Uhr
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.item,
                    b.IsCancelled && classes.cancelled
                  )}
                >
                  {b.NumberPlate.toUpperCase()}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={clsx(
                    classes.item,
                    b.IsCancelled && classes.cancelled
                  )}
                >
                  {b.FirstName} {b.LastName}
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={clsx(
                    classes.item,
                    b.IsCancelled && classes.cancelled
                  )}
                >
                  {b.Street} {b.StreetNumber}, {b.location.Zip}{" "}
                  {b.location.Name}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Schließen
        </Button>
      </DialogActions>
    </>
  );
};

export default SlotBookingInfo;
