import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Box,
  Paper,
  Typography,
  Button,
  Chip,
} from "@material-ui/core";
import API from "../../utils/API";
import Moment from "react-moment";
import Alert from "@material-ui/lab/Alert";
import { red } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
    fontSize: "1.4rem",
    lineHeight: 1.3,
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    color: "#fff",
  },
  chip: {
    background: red[500],
    color: "#fff",
  },
}));

const Cancellation = ({ match }) => {
  const classes = useStyles();
  const [booking, setBooking] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const businessUnit = useSelector((state) => state.businessUnit);

  useEffect(() => {
    if (!match.params.uuid) {
      return;
    }

    setNotFound(false);

    API.get("bookings/uuid/" + match.params.uuid)
      .then((bookingsData) => {
        const bookingsResult = bookingsData.data ? bookingsData.data : null;
        setBooking(bookingsResult);
      })
      .catch((error) => {
        setBooking(null);
        setNotFound(true);
      });
  }, [match.params.uuid]);

  const handleConfirmCancellation = () => {
    API.get("bookings/cancel/" + match.params.uuid)
      .then((bookingsData) => {
        const bookingsResult = bookingsData.data ? bookingsData.data : null;
        setBooking(bookingsResult);
      })
      .catch((error) => {
        setCancelError(true);
      });
  };

  if (!businessUnit) {
    return null;
  }

  const notFoundInfo = (
    <Box mt={3}>
      <Alert severity="error">
        Es wurde keine Buchung gefunden. Bitte vergewissern Sie sich, dass Sie
        den Link vollständig kopiert haben.
      </Alert>
    </Box>
  );


  const cancelErrorInfo = (
    <Box mt={3}>
      <Alert severity="error">
        Stornierung nicht möglich.
      </Alert>
    </Box>
  );

  const isCancelled = booking && booking.IsCancelled && (
    <>
      <Box bgcolor="#f8f8f8" p={4} my={3} display="flex" alignItems="center">
        <Box>
          <Typography variant="h5" gutterBottom>
            <Moment locale="de" format="dddd, D. MMMM YYYY">
              {booking.slot.Date}
            </Moment>
          </Typography>
          <Typography variant="body1" component="div">
            <Moment format="HH:mm" parse="HH:mm:ss.SSS">
              {booking.slot.From}
            </Moment>{" "}
            -{" "}
            <Moment format="HH:mm" parse="HH:mm:ss.SSS">
              {booking.slot.To}
            </Moment>{" "}
            Uhr
          </Typography>
        </Box>
        <Box textAlign="right" marginLeft="auto">
          <Chip label="storniert" className={classes.chip} />
        </Box>
      </Box>
    </>
  );

  const cancellationForm = booking && !booking.IsCancelled && (
    <>
      <Typography
        variant="h2"
        className={classes.subtitle}
        color="textSecondary"
      >
        Sind Sie sicher, dass Sie den folgenden Anliefertermin stornieren
        möchten?
      </Typography>
      <Box bgcolor="#f8f8f8" p={4} my={3}>
        <Typography variant="h5" gutterBottom>
          <Moment locale="de" format="dddd, D. MMMM YYYY">
            {booking.slot.Date}
          </Moment>
        </Typography>
        <Typography variant="body1" component="div">
          <Moment format="HH:mm" parse="HH:mm:ss.SSS">
            {booking.slot.From}
          </Moment>{" "}
          -{" "}
          <Moment format="HH:mm" parse="HH:mm:ss.SSS">
            {booking.slot.To}
          </Moment>{" "}
          Uhr
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.cancelBtn}
        onClick={handleConfirmCancellation}
        style={{ backgroundColor: businessUnit.ColorButtonBackground }}
      >
        Ja, stornieren
      </Button>
    </>
  );

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                Stornierung
              </Typography>
              {notFound && notFoundInfo}
              {cancelError && cancelErrorInfo}
              {!notFound && !cancelError && cancellationForm}
              {!notFound && !cancelError && isCancelled}
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Cancellation;
