import React from "react";
import { Container, Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { grey, blueGrey } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "auto",
    backgroundColor: "rgba(255,255,255,0.5)",
    position: "relative",
    zIndex: 2,
    color: grey[500],
  },
  footerLinks: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
      justifyContent: "center",
    },
  },
  footerLink: {
    margin: theme.spacing(1, 0),
    color: "white",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2),
    },
  },
  phoneIcon: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
  },
  title: {
    opacity: 0.7,
    marginBottom: theme.spacing(2),
  },
  menuItem: {
    height: 30,
    minHeight: 30,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    textTransform: "none",
  },
  logo: {
    height: 60,
    width: "auto",
    margin: theme.spacing(0, 2),
  },
  version: {
    textTransform: "none",
    padding: theme.spacing(0, 1.5),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit) {
    return null;
  }

  const footerMenu = (
    <>
      {[
        {
          title: "Impressum",
          url: businessUnit.ImprintExternalLink,
          to: "/impressum",
          visible: businessUnit.ImprintExternalLink || businessUnit.Imprint,
        },
        {
          title: "Datenschutzerklärung",
          url: businessUnit.PrivacyExternalLink,
          to: "/datenschutz",
          visible: businessUnit.PrivacyExternalLink || businessUnit.Privacy,
        },
        {
          title: businessUnit.RevocationRightTitle,
          url:
            businessUnit.RevocationRightPdf &&
            businessUnit.RevocationRightPdf.url,
          to: undefined,
          visible:
            businessUnit.RevocationRightPdf &&
            businessUnit.RevocationRightPdf.url,
        },
        {
          title: businessUnit.AgbTitle,
          url: businessUnit.AgbPdf && businessUnit.AgbPdf.url,
          to: undefined,
          visible: businessUnit.AgbPdf && businessUnit.AgbPdf.url,
        },
      ]
        .filter((f) => f.visible)
        .map((item, index) => (
          <Button
            size="small"
            className={clsx(classes.menuItem)}
            key={index}
            component={item.url ? "a" : Link}
            href={item.url || undefined}
            target={item.url ? "_blank" : undefined}
            to={item.url ? undefined : item.to}
          >
            {item.title}
          </Button>
        ))}
    </>
  );

  return (
    <>
      <footer className={classes.footer}>
        <Box py={2}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <Box>
                <Box>{footerMenu}</Box>
              </Box>
              <Typography
                variant="overline"
                className={classes.version}
                title={"Versionsnummer: " + process.env.REACT_APP_VERSION}
              >
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Container>
        </Box>
      </footer>
    </>
  );
};

export default Footer;
