import { Box, Button, Typography, TextField, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";


const useStyles = makeStyles((theme) => ({
  btnYes: {
    marginRight: theme.spacing(1),
    color: "#fff"
  },
  btnNext: {
    marginTop: theme.spacing(2),
    color: "#fff"
  },
  btnNo: {
    color: "#fff"
  },
  question: {
    marginBottom: theme.spacing(3),
    fontWeight: "bold"
  },
  infoImg: {
    width: "100%",
    height: "auto"
  }
}));


const LegitimationQuestions = ({ businessUnit, locations, setLegitimationQuestionsSuccess, handleChangeValue, values, setLegitimationQuestionsProtocol }) => {

  const questions = {
    1: "Stammt der Abfall aus Stadt oder Landkreis Kaiserslautern?",
    2: "Bitte wählen Sie Ihren Wohnort aus.",
    3: "Ist der Abfall im Rahmen einer privaten Haushaltsführung entstanden?",
    4: "Ist die Menge des angelieferten Abfalls haushaltsüblich?",
    5: "Beauftragen Sie einen (gewerblichen) Beförderer mit der Anlieferung Ihrer Abfälle?"
  };

  const questionsShort = {
    1: "Stadt oder Landkreis",
    2: "Wohnort",
    3: "Private Haushaltsführung",
    4: "Haushaltsübliche Menge",
    5: "Beförderer beauftragt"
  };

  const lastStep = 5;
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);

  const classes = useStyles();

  const handleYes = () => {
    addProtocol(step, "Ja");
    handleAnswer("yes");
  }

  const handleNo = () => {
    addProtocol(step, "Nein");
    handleAnswer("no");
  }

  const addProtocol = (s, answer) => {
    setLegitimationQuestionsProtocol(current => ([...current, `${questionsShort[s]}: ${answer}`]));
  }

  const handleAnswer = (a) => {
    if (a === "no" && step !== 5) {
      setError(step);
      return;
    }

    if (a === "yes" && step === 5) {
      setError(step);
      return;
    }

    handleNextStep();
  }

  const handleNextStep = () => {

    if (step === lastStep) {
      setLegitimationQuestionsSuccess(true);
      return;
    }
    setStep(s => s + 1);
  }

  const commonError = (
    <>
          <Typography variant="body1" className={classes.error}>
          Bitte nutzen Sie unser kostenpflichtiges Angebot.  
          </Typography>
          <Link
            component="a"
            href="https://www.zak-kl.de/sites/default/files/ZAK_Entgeltliste_1.pdf"
            target="_blank"
          >
            Hier geht's zur Entgeltliste
          </Link>
        </>
  );

  const errorMessage = (
    <>
      {error === 1 && commonError}

      {error === 3 && commonError}

      {error === 4 && commonError}

      {error === 5 && (
        <>
          <Typography variant="body1" className={classes.error}>
          Bitte füllen Sie dieses Formular vor der Anlieferung aus, unterschreiben es und geben es dem Beförderer. Dieser unterschreibt ebenfalls und übergibt uns das Formular bei der Anlieferung. Gehen Sie weiter zur Terminwahl oder, wenn Sie noch keinen Termin kennen, brechen Sie hier ab und der Beförderer bucht den Termin mit den Angaben auf dem Formular. 
          </Typography>

          <Box mb={2} mt={2}>
          <Link
            component="a"
            href="https://www.zak-kl.de/sites/default/files/FB-518%20Deklaration%20von%20Abf%C3%A4llen%20bei%20Anlieferung%20im%20WSH_2021_08_31.pdf"
            target="_blank"
          >
            Hier können Sie das Deklarationsformular herunterladen
          </Link>
          </Box>

          <Box>
          <Button
              variant="contained"
              color="primary"
              onClick={() => {setLegitimationQuestionsSuccess(true)}}
              className={classes.btnYes}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Terminwahl
            </Button>
            </Box>
        </>
      )}
    
    </>
  )

  const questionForm = (
    <>

      
        <Typography variant="body1" className={classes.question}>{questions[step]}</Typography>

        {step === 4 && (
        <>
          <img src="https://www.zak-kl.de/sites/default/files/Abfallwegweiser.jpg" className={classes.infoImg} alt="" />
        </>
      )}

        {step !== 2 && (
          <>
           <Button
              variant="contained"
              color="primary"
              onClick={handleYes}
              className={classes.btnYes}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Ja
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNo}
              className={classes.btnNo}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Nein
            </Button>
          </>
        )}
       
      
      {Boolean( step === 2 && locations && locations.length) && (
        <>
            <Autocomplete
              noOptionsText="kein Eintrag gefunden"
              autoHighlight
              autoSelect
              options={locations}
              getOptionLabel={(location) => `${location.Zip} ${location.Name}`}
              onChange={(e, newValue) => {
                handleChangeValue("location", newValue ? newValue.id : 0)(e);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={values.location}
                  id="no-autocomplete-input"
                  name="no-autocomplete-input"
                  autoComplete="nope"
                  label="PLZ"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStep}
              className={classes.btnNext}
              disabled={!values.location}
              style={{ backgroundColor: businessUnit.ColorButtonBackground }}
            >
              Weiter
            </Button>
          </>
          )}

    

    </>
  )

  return <Box py={4} mb={4} style={{height: 530}}>{error ? errorMessage : questionForm}</Box>;

};

export default LegitimationQuestions;