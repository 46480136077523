import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles, IconButton } from "@material-ui/core";
import LogoutIcon from "mdi-react/LogoutIcon";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btn: {},
}));

const Auth = () => {
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);

  const handleLogout = () => {
    setRedirect(true);
    setTimeout(() => {
      setRedirect(false);
    }, 0);
  };

  if (redirect) {
    return <Redirect to="/logout" />;
  }

  // console.log("AUTH", auth);
  return (
    <>
      {auth && auth.token && (
        <IconButton
          color="primary"
          className={classes.btn}
          onClick={handleLogout}
        >
          <LogoutIcon />
        </IconButton>
      )}
    </>
  );
};

export default Auth;
