import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Box,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import API from "../utils/API";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { red } from "@material-ui/core/colors";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useValues from "@bit/wamedia.tasso.use-values";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";

const useStyles = makeStyles((theme) => ({
  item: {
    borderBottom: "1px solid #eee",
  },
  cancelled: {
    background: red[50],
  },
}));

const initialValues = {
  NumberPlate: "",
  Email: "",
  FirstName: "",
  LastName: "",
  Street: "",
  StreetNumber: "",
  location: 0,
  Wsh: false,
  Sonderabfall: false,
  KidsArea: false,
  IsCancelled: false,
  sendMail: true,
};

const SlotBookingEdit = ({ item, handleClose, handleSave }) => {
  const classes = useStyles();
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const locations = useSelector((state) => state.location.locations);
  const businessUnit = useSelector((state) => state.businessUnit);

  useEffect(() => {
    if (!item || !locations) {
      return;
    }
    const current = Object.assign({}, item);
    current.location = current.location.id;
    current.IsCancelled = !!current.IsCancelled;
    current.sendMail = true;
    delete current["CheckedIn"];
    delete current["CompareNumberPlate"];
    delete current["business_unit"];
    delete current["updated_at"];
    delete current["created_at"];
    delete current["uuid"];
    delete current["slot"];
    delete current["id"];

    setValues(current);
  }, [item, locations]);

  return (
    <>
      <DialogTitle>Buchung bearbeiten</DialogTitle>
      <DialogContent>
        <Box p={4}>
          <Grid container spacing={2}>
            {businessUnit.InputFields.NumberPlate && (
              <Grid item xs={12} md={6} className={classes.firstName}>
                <TsTextField
                  value={values.NumberPlate}
                  handleChange={handleChangeValue}
                  name="NumberPlate"
                  label="Amtliches Kennzeichen des Anlieferfahrzeugs"
                />
              </Grid>
            )}

            {businessUnit.InputFields.Email && (
              <Grid item xs={12} md={6} className={classes.firstName}>
                <TsTextField
                  value={values.Email}
                  handleChange={handleChangeValue}
                  name="Email"
                  label="E-Mail-Adresse"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {businessUnit.InputFields.FirstName && (
              <Grid item xs={12} md={6} className={classes.firstName}>
                <TsTextField
                  value={values.FirstName}
                  handleChange={handleChangeValue}
                  name="FirstName"
                  label="Vorname"
                />
              </Grid>
            )}
            {businessUnit.InputFields.LastName && (
              <Grid item xs={12} md={6} className={classes.lastName}>
                <TsTextField
                  value={values.LastName}
                  handleChange={handleChangeValue}
                  name="LastName"
                  label="Nachname"
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2}>
            {businessUnit.Wsh && (
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Wsh}
                      onChange={(e) => {
                        handleChangeValue("Wsh")(e);
                      }}
                      color="primary"
                    />
                  }
                  label="Wertstoffhof"
                />
              </Grid>
            )}
            {businessUnit.Soa && (
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.Sonderabfall}
                      onChange={(e) => {
                        handleChangeValue("Sonderabfall")(e);
                      }}
                      color="primary"
                    />
                  }
                  label="Sonderabfallannahmestelle"
                />
              </Grid>
            )}
            {businessUnit.KidsArea && (
              <Grid item xs={12} md={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.KidsArea}
                      onChange={(e) => {
                        handleChangeValue("KidsArea")(e);
                      }}
                      color="primary"
                    />
                  }
                  label="Kinderbereich"
                />
              </Grid>
            )}
          </Grid>

          <Box pb={2} pt={5}>
            <Typography variant="h5">
              {businessUnit.WasteOriginTitle}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {businessUnit.InputFields.Street && (
              <Grid item xs={4}>
                <TsTextField
                  value={values.Street}
                  handleChange={handleChangeValue}
                  name="Street"
                  label="Straße"
                />
              </Grid>
            )}
            {businessUnit.InputFields.StreetNumber && (
              <Grid item xs={2} className={classes.streetNumber}>
                <TsTextField
                  value={values.StreetNumber}
                  handleChange={handleChangeValue}
                  name="StreetNumber"
                  label="Haus-Nr."
                />
              </Grid>
            )}
            {businessUnit.InputFields.Location && (
              <>
                {Boolean(locations && locations.length) && (
                  <Grid item xs={6} className={classes.streetNumber}>
                    <Autocomplete
                      value={
                        (locations.filter((f) => f.id === values.location) ||
                          [])[0] || ""
                      }
                      noOptionsText="kein Eintrag gefunden"
                      autoHighlight
                      autoSelect
                      options={locations}
                      getOptionLabel={(location) =>
                        location.Zip ? `${location.Zip} ${location.Name}` : ""
                      }
                      onChange={(e, newValue) => {
                        handleChangeValue(
                          "location",
                          newValue ? newValue.id : 0
                        )(e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={values.location}
                          id="no-autocomplete-input"
                          name="no-autocomplete-input"
                          autoComplete="nope"
                          label="PLZ"
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                    />
                  </Grid>
                )}
                {(!locations || !locations.length) && (
                  <>
                    <Grid item xs={2} className={classes.streetNumber}>
                      <TsTextField
                        value={values.Zip}
                        handleChange={handleChangeValue}
                        name="Zip"
                        label="PLZ"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TsTextField
                        value={values.City}
                        handleChange={handleChangeValue}
                        name="City"
                        label="Ort"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsCancelled}
                    onChange={(e) => {
                      handleChangeValue("IsCancelled")(e);
                    }}
                    color="primary"
                  />
                }
                label="Storniert"
              />
            </Grid>
            <Grid item xs={8}>
              {!values.IsCancelled && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sendMail}
                      onChange={(e) => {
                        handleChangeValue("sendMail")(e);
                      }}
                      color="primary"
                    />
                  }
                  label="Bestätigungs-E-Mail versenden"
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            handleSave(values, item.uuid, handleClose);
          }}
          color="primary"
        >
          Speichern
        </Button>
      </DialogActions>
    </>
  );
};

export default SlotBookingEdit;
