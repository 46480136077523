import React from "react";
import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import { grey, yellow, teal } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  divider: {
    backgroundColor: grey[400],
  },
  val: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  highlight: {
    background: teal[50],
  },
}));

const BookingFormSelectionSummary = ({ booking }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (!businessUnit || !booking) {
    return null;
  }

  const selections = [
    {
      title: "WSH",
      value: booking.Wsh ? "Ja" : "Nein",
      show: businessUnit.Wsh,
    },
    {
      title: "SOA",
      value: booking.Soa ? "Ja" : "Nein",
      show: businessUnit.Soa,
    },
    {
      title: "Kinderbereich",
      value: booking.KidsArea ? "Ja" : "Nein",
      show: businessUnit.KidsArea,
    },
  ];

  return (
    <>
      <Box className={classes.list}>
        {selections
          .filter((f) => f.show)
          .map((item, idx, arr) => (
            <React.Fragment key={idx}>
              <Box
                p={2}
                display="flex"
                className={clsx(item.value === "Ja" && classes.highlight)}
              >
                <Box
                  width={120}
                  flexShrink={0}
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="body1" component="span">
                    <strong>{item.title}</strong>
                  </Typography>
                </Box>
                <Box flexGrow={1} textAlign="right">
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.val}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Box>
              {idx < arr.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
      </Box>
    </>
  );
};

export default BookingFormSelectionSummary;
