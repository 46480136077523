import API from "../../utils/API";

export const getData = () => async (dispatch, getState) => {
  API.get("business-units/current")
    .then((buData) => {
      // console.log(locationsData);
      const businessUnit = buData.data ? buData.data : null;
      dispatch({
        type: "SET_BUSINESS_UNIT",
        businessUnit,
      });

      if (!businessUnit.Warning || !businessUnit.Warning.WarningActive) {
        dispatch({
          type: "SET_BREAKING_NEWS",
          hidden: false,
          content: "",
        });
        return;
      }
      const existing = getState().breakingNews;
      if (
        existing &&
        existing.updatedAt === businessUnit.Warning.WarningUpdatedAt
      ) {
        return;
      }
      if (businessUnit.Warning.WarningActive) {
        dispatch({
          type: "SET_BREAKING_NEWS",
          hidden: false,
          content: businessUnit.Warning.WarningText,
          date: businessUnit.Warning.WarningUpdatedAt,
        });
      }
    })
    .catch((error) => {
      ////
      API.get("business-unit-selects/current")
        .then((buData) => {
          // console.log(locationsData);
          const businessUnit = buData.data ? buData.data : null;
          businessUnit["isBusinessUnitSelect"] = true;
          dispatch({
            type: "SET_BUSINESS_UNIT",
            businessUnit,
          });
        })
        .catch((error) => {
          dispatch({
            type: "SET_BUSINESS_UNIT",
            businessUnit: null,
          });
        });
      ////
    });
};
