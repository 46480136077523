import React, { useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import API from "../utils/API";
import { useSelector } from "react-redux";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  btnDelete: {
    color: red[500],
    marginRight: "auto"
  }
}));

const SlotEdit = ({slotDate, slot, open, handleClose }) => {
  const [seats, setSeats] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [info, setInfo] = useState("");
  const [id, setId] = useState("");
  const [date, setDate] = useState("");
  const inputRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      return;
    }
    if (slot) {
      setId(slot.id);
      setSeats(slot.Seats);
      setFrom(formatTime(slot.From));
      setTo(formatTime(slot.To));
      setInfo(slot.Info || "");
    }

    if (!slot && slotDate) {
      setDate(slotDate);
    }
    
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  }, [open]);

  const handleChange = (event) => {
    setSeats(event.target.value);
  };

  const handleChangeFrom = (event) => {
    setFrom(event.target.value);
  };

  const handleChangeTo = (event) => {
    setTo(event.target.value);
  };

  const handleChangeInfo = (event) => {
    setInfo(event.target.value);
  };

  const formatTime = (s) => {
    const f = s.replace(/\./, ":").replace(/\,/, ":");
    if (!f) {
      return "";
    }
    const parts = f.split(":");

    let hours = "";
    let minutes = "";
    if (parts[0].length === 2) {
      hours = parts[0];
    } else if (parts[0].length === 1) {
      hours = "0" + parts[0];
    } else if (parts[0].length > 2) {
      hours = parts[0].substr(0, 2);
    }

    if (!parts[1]) {
      minutes = "00";
    } else if (parts[1].length === 1) {
      minutes = parts[1] + "0";
    } else if (parts[1].length === 2) {
      minutes = parts[1];
    } else {
      minutes = "00";
    }

    if (parseInt(hours) > 23) {
      hours = "00";
    }

    if (parseInt(minutes) > 59) {
      hours = "00";
    }

    let time = hours + ":" + minutes;
    if (!time.match(/^\d{2,2}:\d{2,2}$/)) {
      time = "";
    }

    return time;
  }

  const formatFrom = () => {
    setFrom(formatTime(from));
  }

  const formatTo = () => {
    setTo(formatTime(to));
  }

  const onSave = (e) => {
    e.preventDefault();
    if (!from || !to) {
      return;
    }
    const body = {
      id,
      seats,
      from: `${from}:00.000`,
      to: `${to}:00.000`,
      info
    };
    if (!id) {
      body.date = date;
    }
    API.post("slots", body, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((result) => {
        const slotResult = result.data ? result.data : null;
        // console.log(slotResult);
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (!id) {
      return;
    }

    API.delete(`slots/${id}`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((result) => {
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClose = () => {
    setId("");
    setSeats(0);
    setFrom("");
    setTo("");
    setInfo("");
    setDate("");
    handleClose();
  }

  return (
    <>
      <Dialog open={open} size="sm" fullWidth>
        <DialogTitle>Slot {id ? "bearbeiten" : "erstellen"}</DialogTitle>
        <DialogContent>
          <form onSubmit={onSave}>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <TextField
                  inputRef={(r) => {
                    inputRef.current = r;
                  }}
                  value={seats}
                  onChange={handleChange}
                  label="Plätze"
                  variant="outlined"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  value={from}
                  onChange={handleChangeFrom}
                  onBlur={formatFrom}
                  label="Von"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  value={to}
                  onChange={handleChangeTo}
                  onBlur={formatTo}
                  label="Bis"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  value={info}
                  onChange={handleChangeInfo}
                  label="Info (optional)"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {id && (<Button onClick={onDelete} className={classes.btnDelete}>Löschen</Button>)}
          
          <Button onClick={onClose}>Abbrechen</Button>
          <Button variant="contained" onClick={onSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SlotEdit;
