import React from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  makeStyles,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  divider: {
    backgroundColor: grey[400],
  },
  mdText: {
    "&>p": {
      margin: 0,
    },
  },
  label: {
    fontSize: 14,
    position: "relative",
    top: -2,
    fontWeight: 700,
  },
  missing: {
    boxShadow: "0 0 3px red inset",
    border: "1px solid red",
  },
}));

const BookingAcceptBox = ({ what, handleChange, accept }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  if (
    !businessUnit ||
    (!businessUnit[what + "Short"] &&
      !businessUnit[what + "CheckboxLabel"] &&
      !businessUnit[what + "Pdf"])
  ) {
    return null;
  }

  const sections = [];

  if (businessUnit[what + "Short"]) {
    sections.push({
      item: (
        <>
          <Typography
            variant="body2"
            component="div"
            className={classes.mdText}
          >
            <ReactMarkdown
              source={businessUnit[what + "Short"]}
              renderers={{
                link: (props) => (
                  <a
                    href={props.href}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {props.children}
                  </a>
                ),
              }}
            />
          </Typography>
        </>
      ),
      boxClass: undefined,
    });
  }

  if (businessUnit[what + "Pdf"] && businessUnit[what + "Pdf"].url) {
    sections.push({
      item: (
        <>
          <Typography
            variant="body2"
            component="div"
            className={classes.mdText}
          >
            <a
              href={businessUnit[what + "Pdf"].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {businessUnit[what + "Title"]} ansehen
            </a>
          </Typography>
        </>
      ),
      boxClass: undefined,
    });
  }

  if (businessUnit[what + "CheckboxLabel"]) {
    sections.push({
      item: (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={accept}
                onChange={handleChange}
                color="primary"
              />
            }
            label={
              <Typography component="span" className={classes.label}>
                {businessUnit[what + "CheckboxLabel"]}
              </Typography>
            }
          />
        </>
      ),
      boxClass: accept ? undefined : classes.missing,
    });
  }

  return (
    <>
      <Box className={classes.list} my={3}>
        {sections.map((content, idx, arr) => (
          <React.Fragment key={idx}>
            <Box p={2} display="flex" className={content.boxClass}>
              <Box display="flex" alignItems="flex-start">
                {content.item}
              </Box>
            </Box>
            {idx < arr.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default BookingAcceptBox;
