const initState = null;

const businessUnitReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_BUSINESS_UNIT":
      return action.businessUnit;
    default:
      return state;
  }
};

export default businessUnitReducer;
