import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import * as constants from "./constants";
import {
  CssBaseline,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Container,
} from "@material-ui/core";
import Layout from "./components/Layout";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/pages/Home";
import Page from "./components/pages/Page";
import "./index.css";
import ScrollMemory from "react-router-scroll-memory";
import Success from "./components/pages/Success";
import Article from "./components/pages/Article";
import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";
import Faq from "./components/pages/Faq";
import Login from "./components/pages/Login";
import Bookings from "./components/pages/Bookings";
import Logout from "./components/pages/Logout";
import Cancellation from "./components/pages/Cancellation";
import { useDispatch } from "react-redux";
import * as businessUnitActions from "./store/actions/businessUnitActions";
import * as locationActions from "./store/actions/locationActions";
import * as shopProductsActions from "./store/actions/shopProductsActions";
import Hinweise from "./components/pages/Hinweise";
import Imprint from "./components/pages/Imprint";
import Privacy from "./components/pages/Privacy";
import HomeRoot from "./components/pages/HomeRoot";
import { useSelector } from "react-redux";
import API from "./utils/API";
import CheckShopOrder from "./components/pages/CheckShopOrder";
import CheckoutBooking from "./components/pages/CheckoutBooking";
import RevocationRight from "./components/pages/RevocationRight";
import MyBookingsRequest from "./components/pages/MyBookingsRequest";
import MyBookings from "./components/pages/MyBookings";
import PaymentError from "./components/pages/PaymentError";
import CurrentlyCheckedIn from "./components/pages/CurrentlyCheckedIn";

const pusherClient = new Pusher(
  process.env["REACT_APP_PUSHER_KEY" + window.ENV_POSTFIX] ||
    process.env.REACT_APP_PUSHER_KEY,
  {
    cluster: "eu",
    forceTLS: true,
  }
);
setPusherClient(pusherClient);

function App() {
  const dispatch = useDispatch();
  const [preLaunchAuth, setPreLaunchAuth] = useState(false);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [showLogin, setShowLogin] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(businessUnitActions.getData());
    dispatch(locationActions.getData());
    dispatch(shopProductsActions.getData());
  }, []);

  useEffect(() => {
    if (!businessUnit) {
      return;
    }

    if (businessUnit.FrontendPasswordActive) {
      setShowLogin(true);
    } else {
      setPreLaunchAuth(true);
    }
  }, [businessUnit]);

  const checkFrontendPassword = (e) => {
    e.preventDefault();
    API.post("business-units/auth/check-frontend-password", { password })
      .then((res) => {
        // console.log(locationsData);
        const checkRes = res.data ? res.data : null;
        if (checkRes.check === true) {
          setPreLaunchAuth(true);
        }
      })
      .catch((error) => {});
  };

  if (!preLaunchAuth && showLogin) {
    return (
      <Box py={10}>
        <Container maxWidth="sm">
          <Box>
            <Box mb={4}>
              <Typography variant="h4" align="center">
                Diese Seite ist noch nicht öffentlich zugänglich
              </Typography>
            </Box>
            <Paper style={{ backgroundColor: "#f8f8f8" }}>
              <Box p={8}>
                <form onSubmit={checkFrontendPassword}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    label="Passwort"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                  <Box mt={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Login
                    </Button>
                  </Box>
                </form>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    );
  }

  if (!preLaunchAuth) {
    return null;
  }

  // console.log("API_URL", process.env.REACT_APP_API_URL);
  return (
    <BrowserRouter>
      <ScrollMemory />
      <ThemeProvider theme={constants.THEME}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path="/" component={HomeRoot} />
            <Route
              exact
              path="/kasse/:checkoutBookingUuid"
              component={CheckoutBooking}
            />
            <Route exact path="/storno/:uuid" component={Cancellation} />
            <Route exact path="/reservierungen" component={Bookings} />
            <Route exact path="/check-shop-order" component={CheckShopOrder} />
            <Route exact path="/currently-checked-in" component={CurrentlyCheckedIn} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/hinweise" component={Hinweise} />
            <Route exact path="/impressum" component={Imprint} />
            <Route exact path="/datenschutz" component={Privacy} />
            <Route exact path="/widerrufsrecht" component={RevocationRight} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/payment-error" component={PaymentError} />
            <Route exact path="/:slug" component={Page} />
            <Route exact path="/buchung/:uuid" component={Success} />
            <Route exact path="/news/:id" component={Article} />
            <Route
              exact
              path="/meine-buchungen/anfordern"
              component={MyBookingsRequest}
            />
            <Route
              exact
              path="/meine-buchungen/uebersicht/:uuid"
              component={MyBookings}
            />
          </Switch>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
