import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
} from "@material-ui/core";
import API from "../../utils/API";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "normal",
    fontSize: "1.4rem",
    lineHeight: 1.3,
    marginTop: theme.spacing(3),
  },
  bodyText: {
    lineHeight: 1.4,
  },
  date: {
    display: "block",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: "0.8rem",
  },
}));

const Article = ({ match }) => {
  const [article, setArticle] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setArticle(null);
    if (!match.params.id) {
      return;
    }

    API.get("articles/" + match.params.id)
      .then((newsData) => {
        // console.log(newsData);
        const newsResult = newsData.data ? newsData.data : null;
        setArticle(newsResult);
      })
      .catch((error) => {
        setArticle(null);
      });
  }, [match.params.id]);

  // console.log(article);

  if (!article) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                {article.Title}
              </Typography>
              <Typography variant="h2" className={classes.subtitle}>
                {article.Subtitle}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.date}
              >
                <strong>Kaiserslautern</strong>,{" "}
                <Moment locale="de" format="dddd, D. MMMM YYYY">
                  {article.Override_updated_at || article.updated_at}
                </Moment>
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.bodyText}
              >
                <ReactMarkdown source={article.Body} />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Article;
