import { combineReducers } from "redux";
import reservationReducer from "./reservationReducer";
import breakingNewsReducer from "./breakingNewsReducer";
import locationReducer from "./locationReducer";
import authReducer from "./authReducer";
import businessUnitReducer from "./businessUnitReducer";
import shopProductsReducer from "./shopProductsReducer";
import savedBookingForPaymentReducer from "./savedBookingForPaymentReducer";

const rootReducer = combineReducers({
  reservation: reservationReducer,
  breakingNews: breakingNewsReducer,
  location: locationReducer,
  auth: authReducer,
  businessUnit: businessUnitReducer,
  shopProducts: shopProductsReducer,
  savedBookingForPayment: savedBookingForPaymentReducer,
});

export default rootReducer;
