import React from "react";
import { Paper, Box, Typography, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  price: {
    fontWeight: 600,
    fontSize: 20,
  },
  totalBox: {
    background: grey[100],
    border: "1px solid transparent",
    borderColor: grey[400],
  },
  ust: {
    borderTop: "1px solid transparent",
    borderTopColor: grey[400],
  },
}));

const BookingShopTotalPrice = ({ cart }) => {
  const classes = useStyles();

  const totalPrice = cart.reduce((a, b) => a + b.product.Price * b.quantity, 0);

  if (!totalPrice) {
    return null;
  }

  return (
    <>
      <Paper className={classes.totalBox} elevation={2}>
        <Box p={2} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="body1" component="div">
              <strong>Gesamtpreis:</strong>
            </Typography>
          </Box>
          <Box width={100} flexShrink={0} textAlign="right">
            <Typography variant="h4" component="div" className={classes.price}>
              <strong>
                {new Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(
                  totalPrice
                )}{" "}
                €
              </strong>
            </Typography>
          </Box>
        </Box>
        {[...new Set(cart.map((c) => c.product.Tax))]
          .filter((f) => f > 0)
          .map((tax, idx) => (
            <Box p={2} display="flex" key={idx} className={classes.ust}>
              <Box flexGrow={1}>
                <Typography variant="body2" component="div">
                  enthaltene MwSt. {tax} %
                </Typography>
              </Box>
              <Box width={100} flexShrink={0} textAlign="right">
                <Typography variant="body2" component="div">
                  {new Intl.NumberFormat("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    cart
                      .filter((f) => f.product.Tax === tax)
                      .reduce(
                        (a, b) =>
                          a +
                          b.quantity *
                            (b.product.Price -
                              b.product.Price / ((100 + tax) / 100)),
                        0
                      )
                  )}{" "}
                  €
                </Typography>
              </Box>
            </Box>
          ))}
      </Paper>
    </>
  );
};

export default BookingShopTotalPrice;
