import React from "react";
import {
  Typography,
  Paper,
  Box,
  makeStyles,
  Divider,
  IconButton,
  Button,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { grey, red, green, teal } from "@material-ui/core/colors";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import AlertIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import { useSelector } from "react-redux";
import BookingShopTotalPrice from "./BookingShopTotalPrice";
import BookingShopCart from "./BookingShopCart";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  bodyText: {
    color: grey[600],
    fontSize: 14,
    marginTop: theme.spacing(1),
    "&>p": {
      margin: 0,
    },
  },
  list: {
    border: "1px solid transparent",
    borderColor: grey[400],
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  divider: {
    backgroundColor: grey[400],
  },
  stepper: {
    background: grey[200],
    height: 54,
    borderRadius: 27,
    width: 150,
    paddingLeft: 2,
    paddingRight: 2,
    transition: "background-color 0.2s ease-in",
  },
  price: {
    fontWeight: 600,
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  currency: {
    fontSize: 20,
    color: grey[600],
  },
  unit: {
    color: grey[600],
  },
  je: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    color: grey[600],
  },
  warningIcon: {
    color: red[500],
    marginRight: theme.spacing(3),
  },
  item: {
    backgroundColor: "rgba(255,255,255,0)",
    transition: "background-color 0.2s ease-in",
  },
  itemActive: {
    backgroundColor: teal[50],
  },
  btnBooking: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#fff",
  },
}));

const BookingShopProducts = ({ cart, setCart, onNext, availableSeats }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);

  const onMinus = (item) => {
    const newCart = cart.slice().map((m) => ({
      product: Object.assign({}, m.product),
      quantity: m.quantity,
    }));
    for (const c of newCart) {
      if (c.product.id === item.product.id) {
        c.quantity = c.quantity - 1;
      }
    }
    setCart(newCart);
  };

  const onPlus = (item) => {
    const newCart = cart.slice().map((m) => ({
      product: Object.assign({}, m.product),
      quantity: m.quantity,
    }));
    for (const c of newCart) {
      if (c.product.id === item.product.id) {
        c.quantity = c.quantity + 1;
      }
    }
    setCart(newCart);
  };

  if (!businessUnit) {
    return null;
  }

  const leftPlaces =
    Math.min(6, availableSeats) -
    cart.reduce((a, b) => a + b.quantity * (b.product.ReservedPlaces || 1), 0);

  const isDisabled = 0 >= cart.reduce((a, b) => a + b.quantity, 0);

  console.log("availableSeats", availableSeats);
  console.log("leftPlaces", leftPlaces);

  return (
    <div className={classes.root}>
      {leftPlaces <= 0 && (
        <Box display="flex" alignItems="center" mb={1} p={2}>
          <AlertIcon className={classes.warningIcon} />
          <Typography variant="body2" component="div">
            Sie haben die maximale Gesamtzahl an möglichen Plätzen pro Buchung
            erreicht.
          </Typography>
        </Box>
      )}
      <Box className={classes.list}>
        {cart &&
          cart.map((item, idx) => (
            <React.Fragment key={idx}>
              <Box
                p={2}
                display="flex"
                alignItems="flex-start"
                className={clsx(
                  classes.item,
                  item.quantity > 0 && classes.itemActive
                )}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box flexGrow={1} pr={3}>
                  <Typography variant="h5">{item.product.Title}</Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    className={classes.bodyText}
                  >
                    <ReactMarkdown
                      source={item.product.Description}
                      renderers={{
                        link: (props) => (
                          <a
                            href={props.href}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {props.children}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexShrink={0}
                  width={{ xs: "100%", sm: 150 }}
                  flexDirection={{ xs: "row", sm: "column" }}
                  alignItems="center"
                  justifyContent={{ xs: "space-between", sm: "flex-start" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  {Boolean(item.product.Price) && (
                    <Box
                    flexShrink={0}
                    width={{ xs: "auto", sm: "100%" }}
                    textAlign="center"
                    mb={1}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.je}
                    >
                      je
                    </Typography>
                    <Typography
                      variant="h4"
                      component="span"
                      className={classes.price}
                    >
                      {new Intl.NumberFormat("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(item.product.Price)}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.currency}
                    >
                      €
                    </Typography>
                  </Box>
                  )}
                  
                  <Box
                    display="flex"
                    className={classes.stepper}
                    justifyContent="space-between"
                    alignItems="center"
                    style={
                      item.quantity <= 0
                        ? undefined
                        : {
                            backgroundColor: businessUnit.ColorHeaderBackground,
                            color: "#fff",
                          }
                    }
                  >
                    <IconButton
                      color="inherit"
                      size="medium"
                      disabled={item.quantity <= 0}
                      onClick={() => {
                        onMinus(item);
                      }}
                    >
                      <MinusIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h6">{item.quantity}</Typography>
                    <IconButton
                      color="inherit"
                      size="medium"
                      onClick={() => {
                        onPlus(item);
                      }}
                      disabled={
                        leftPlaces < item.product.ReservedPlaces ||
                        item.quantity >= item.product.MaxQuantity
                      }
                    >
                      <PlusIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              {idx < cart.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
      </Box>
      <Box mt={2}>
        <BookingShopTotalPrice cart={cart} />
      </Box>
      <Button
        disabled={isDisabled}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.btnBooking}
        onClick={onNext}
        style={
          isDisabled
            ? undefined
            : {
                backgroundColor: businessUnit.ColorButtonBackground,
                color: businessUnit.ColorButtonText || "#fff",
              }
        }
      >
        Weiter
      </Button>
    </div>
  );
};

export default BookingShopProducts;
