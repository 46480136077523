const initState = {
  locations: []
};

const locationReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_LOCATIONS":
      return {
        ...state,
        locations: action.locations
      };
    default:
      return state;
  }
};

export default locationReducer;
