import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Chip,
  Box,
} from "@material-ui/core";
import EditIcon from "mdi-react/PencilIcon";
import EmailIcon from "mdi-react/EmailIcon";
import InfoIcon from "mdi-react/InformationIcon";
import CheckBlankIcon from "mdi-react/CheckboxBlankOutlineIcon";
import CheckMarkedIcon from "mdi-react/CheckboxMarkedOutlineIcon";
import SlotBookingInfo from "./SlotBookingInfo";
import clsx from "clsx";
import { red, grey } from "@material-ui/core/colors";
import SlotBookingEdit from "./SlotBookingEdit";
import { useSelector } from "react-redux";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  item: {
    borderBottom: "1px solid #eee",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    justifyContent: "flex-start",
  },
  cancelled: {
    background: red[50],
  },
  chip: {
    background: red[500],
    color: "#fff",
    marginRight: theme.spacing(1),
  },
  check: {
    marginRight: theme.spacing(2),
    background: "#fff",
  },
  IconCheckedIn: {
    color: theme.palette.primary.main,
  },
  checkedIn: {
    opacity: 0.5,
    position: "relative",
    background: "#eee",
  },
  editBtn: {
    marginLeft: "auto",
  },
  paymentKind: {
    paddingLeft: 30,
    fontSize: 12,
    color: grey[500],
  },
}));

const mapPaymentKind = {
  ec: "EC-Karte",
  creditCard: "Kreditkarte",
  giroPay: "GiroPay",
};

const SlotBookingItem = ({ item, onCheckIn, onCheckOut, onSave }) => {
  const classes = useStyles();
  const [infoOpen, setInfoOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const businessUnit = useSelector((state) => state.businessUnit);

  const handleInfoOpen = () => {
    setInfoOpen(true);
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const handleEmailOpen = () => {
    setEmailOpen(true);
  };

  const handleEmailClose = () => {
    setEmailOpen(false);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  if (!businessUnit || !businessUnit.Stats) {
    return null;
  }

  const selections = [];
  if (businessUnit.Wsh) {
    selections.push({
      title: "WSH",
      itemProp: "Wsh",
    });
  }

  if (businessUnit.Soa) {
    selections.push({
      title: "SOA",
      itemProp: "Sonderabfall",
    });
  }

  if (businessUnit.KidsArea) {
    selections.push({
      title: "Kinderb.",
      itemProp: "KidsArea",
    });
  }

  return (
    <div
      className={clsx(
        classes.item,
        item.IsCancelled && classes.cancelled,
        item.CheckedIn && classes.checkedIn
      )}
    >
      {businessUnit.Stats.CheckInButton && (
        <div style={{ width: 40 }}>
          {!item.IsCancelled && (
            <IconButton
              size="small"
              onClick={() => {
                onCheckIn(item.uuid, !!item.CheckedIn);
              }}
              className={classes.check}
            >
              {!item.CheckedIn && <CheckBlankIcon />}
              {item.CheckedIn && (
                <CheckMarkedIcon className={classes.IconCheckedIn} />
              )}
            </IconButton>
          )}
        </div>
      )}

      {businessUnit.Stats.CheckInDate && (
        <div style={{ width: 200 }}>
          {item.CheckedInAt ? (
            <Moment format="DD.MM.YYYY - HH:mm">{item.CheckedInAt}</Moment>
          ) : (
            "Check-In"
          )}
        </div>
      )}

      {businessUnit.Stats.CheckOutButton && (
        <div style={{ width: 40 }}>
          {!item.IsCancelled && (
            <IconButton
              size="small"
              onClick={() => {
                onCheckOut(item.uuid, !!item.CheckedOut);
              }}
              className={classes.check}
            >
              {!item.CheckedOut && <CheckBlankIcon />}
              {item.CheckedOut && (
                <CheckMarkedIcon className={classes.IconCheckedIn} />
              )}
            </IconButton>
          )}
        </div>
      )}

      {businessUnit.Stats.CheckOutDate && (
        <div style={{ width: 200 }}>
          {item.CheckedOutAt ? (
            <Moment format="DD.MM.YYYY - HH:mm">{item.CheckedOutAt}</Moment>
          ) : (
            "Check-Out"
          )}
        </div>
      )}

      <div style={{ width: 120 }}>
        {item.IsCancelled && (
          <Chip label="storniert" size="small" className={classes.chip} />
        )}
      </div>

      {item.NumberPlate !== "NONE" && (
        <div style={{ width: 200 }}>
          <IconButton size="small" onClick={handleInfoOpen}>
            <InfoIcon />
          </IconButton>

          <strong>
            {item.NumberPlate.replace(/\(.+?\)/g, "")
              .replace(/[^a-z0-9+]+/gi, " ")
              .toUpperCase()}
          </strong>
        </div>
      )}
      <div style={{ width: 250 }}>
        <IconButton size="small" onClick={handleEmailOpen}>
          <EmailIcon />
        </IconButton>
        {item.FirstName} {item.LastName}{" "}
        {item.shop_order && (
          <Typography className={classes.paymentKind}>
            {mapPaymentKind[item.shop_order.PaymentKind] ||
              item.shop_order.PaymentKind}
          </Typography>
        )}
      </div>

      {selections.map((sel, selIdx) => (
        <div style={{ width: 70 }} key={selIdx}>
          {item[sel.itemProp] ? sel.title : ""}
        </div>
      ))}

      <div style={{ width: 200 }}>
        {item.Street} {item.StreetNumber}
      </div>
      <div style={{ width: 250 }}>
        {Boolean(item.location) && (
          <>
            {item.location.Zip} {item.location.Name}
          </>
        )}
        {Boolean(item.Zip && item.City) && (
          <>
            {item.Zip} {item.City}
          </>
        )}
      </div>
      <div style={{ width: 40, marginLeft: "auto" }}>
        <IconButton
          size="small"
          onClick={handleEditOpen}
          className={classes.editBtn}
        >
          <EditIcon />
        </IconButton>
      </div>
      <Dialog open={infoOpen} maxWidth="lg" fullWidth>
        {infoOpen && (
          <SlotBookingInfo
            numberPlate={item.NumberPlate}
            handleClose={handleInfoClose}
          />
        )}
      </Dialog>
      <Dialog open={emailOpen} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="body1">
            {item.FirstName} {item.LastName}
          </Typography>
          <Typography variant="body1">
            <a href={"mailto:" + item.Email}>{item.Email}</a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailClose} color="primary" autoFocus>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} maxWidth="lg" fullWidth>
        {editOpen && (
          <SlotBookingEdit
            item={item}
            handleClose={handleEditClose}
            handleSave={onSave}
          />
        )}
      </Dialog>
    </div>
  );
  /*
  return (
    <Box
      p={1}
      display="flex"
      justifyContent="flex-start"
      className={clsx(
        classes.item,
        item.IsCancelled && classes.cancelled,
        item.CheckedIn && classes.checkedIn
      )}
    >
      {businessUnit.Stats.CheckInButton && (
        <Box width={40}>
          {!item.IsCancelled && (
            <IconButton
              size="small"
              onClick={() => {
                onCheckIn(item.uuid, !!item.CheckedIn);
              }}
              className={classes.check}
            >
              {!item.CheckedIn && <CheckBlankIcon />}
              {item.CheckedIn && (
                <CheckMarkedIcon className={classes.IconCheckedIn} />
              )}
            </IconButton>
          )}
        </Box>
      )}

      {businessUnit.Stats.CheckInDate && (
        <Box width={200}>
          {item.CheckedInAt ? (
            <Moment format="DD.MM.YYYY - HH:mm">{item.CheckedInAt}</Moment>
          ) : (
            "Check-In"
          )}
        </Box>
      )}

      {businessUnit.Stats.CheckOutButton && (
        <Box width={40}>
          {!item.IsCancelled && (
            <IconButton
              size="small"
              onClick={() => {
                onCheckOut(item.uuid, !!item.CheckedOut);
              }}
              className={classes.check}
            >
              {!item.CheckedOut && <CheckBlankIcon />}
              {item.CheckedOut && (
                <CheckMarkedIcon className={classes.IconCheckedIn} />
              )}
            </IconButton>
          )}
        </Box>
      )}

      {businessUnit.Stats.CheckOutDate && (
        <Box width={200}>
          {item.CheckedOutAt ? (
            <Moment format="DD.MM.YYYY - HH:mm">{item.CheckedOutAt}</Moment>
          ) : (
            "Check-Out"
          )}
        </Box>
      )}

      <Box width={120}>
        {item.IsCancelled && (
          <Chip label="storniert" size="small" className={classes.chip} />
        )}
      </Box>

      {item.NumberPlate !== "NONE" && (
        <Box width={200}>
          <IconButton size="small" onClick={handleInfoOpen}>
            <InfoIcon />
          </IconButton>

          <strong>
            {item.NumberPlate.replace(/\(.+?\)/g, "")
              .replace(/[^a-z0-9+]+/gi, " ")
              .toUpperCase()}
          </strong>
        </Box>
      )}
      <Box width={250}>
        <IconButton size="small" onClick={handleEmailOpen}>
          <EmailIcon />
        </IconButton>
        {item.FirstName} {item.LastName}{" "}
        {item.shop_order && (
          <Typography className={classes.paymentKind}>
            {mapPaymentKind[item.shop_order.PaymentKind] ||
              item.shop_order.PaymentKind}
          </Typography>
        )}
      </Box>

      {selections.map((sel, selIdx) => (
        <Box width={70} key={selIdx}>
          {item[sel.itemProp] ? sel.title : ""}
        </Box>
      ))}

      <Box width={200}>
        {item.Street} {item.StreetNumber}
      </Box>
      <Box width={250}>
        {Boolean(item.location) && (
          <>
            {item.location.Zip} {item.location.Name}
          </>
        )}
        {Boolean(item.Zip && item.City) && (
          <>
            {item.Zip} {item.City}
          </>
        )}
      </Box>
      <Box width={40} ml="auto">
        <IconButton
          size="small"
          onClick={handleEditOpen}
          className={classes.editBtn}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Dialog open={infoOpen} maxWidth="lg" fullWidth>
        {infoOpen && (
          <SlotBookingInfo
            numberPlate={item.NumberPlate}
            handleClose={handleInfoClose}
          />
        )}
      </Dialog>
      <Dialog open={emailOpen} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="body1">
            {item.FirstName} {item.LastName}
          </Typography>
          <Typography variant="body1">
            <a href={"mailto:" + item.Email}>{item.Email}</a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailClose} color="primary" autoFocus>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editOpen} maxWidth="lg" fullWidth>
        {editOpen && (
          <SlotBookingEdit
            item={item}
            handleClose={handleEditClose}
            handleSave={onSave}
          />
        )}
      </Dialog>
    </Box>
  );
  */
};

export default SlotBookingItem;
