import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

const useScreensize = () => {
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));

  let current = "xs";
  if (sm) current = "sm";
  if (md) current = "md";
  if (lg) current = "lg";
  if (xl) current = "xl";

  return { xs, sm, md, lg, xl, current };
};

export default useScreensize;
