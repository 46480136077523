import React, { useState } from "react";
import DropIn from "braintree-web-drop-in-react";
import { useEffect } from "react";
import API from "../utils/API";
import {
  Button,
  makeStyles,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import BookingShopCart from "./BookingShopCart";
import BookingShopTotalPrice from "./BookingShopTotalPrice";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./StripeCardSectionStyles.css";
import BookingFormSummary from "./BookingFormSummary";
import BookingFormSelectionSummary from "./BookingFormSelectionSummary";
import BookingPaymentKinds from "./BookingPaymentKinds";
import ReactMarkdown from "react-markdown";
import { grey, red } from "@material-ui/core/colors";
import BookingAcceptBox from "./BookingAcceptBox";

const useStyles = makeStyles((theme) => ({
  root: {},
  btnBooking: {
    color: "#fff",
  },
  btnCancel: {
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    marginBottom: theme.spacing(1),
    position: "relative",
  },
  labelBooking: {
    opacity: 0.4,
  },
  revocationText: {
    fontSize: 12,
  },
  revocationLabel: {
    fontSize: 14,
    position: "relative",
    top: -2,
    fontWeight: 700,
  },
  cardError: {
    color: red[500],
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
}));

function promiseBookingWithShopOrder(funcToCall, stripeIntentId = null) {
  return new Promise((resolve, reject) => {
    funcToCall(stripeIntentId, (res) => {
      if (res.booking && res.shopOrder) {
        resolve(res);
      } else {
        reject("Error");
      }
    });
  });
}

function promiseStripeIntent(funcToCall, uuid, stripePaymentKind) {
  return new Promise((resolve, reject) => {
    funcToCall(uuid, stripePaymentKind, (res) => {
      if (res) {
        // console.log(res);
        resolve(res);
      } else {
        reject("Error");
      }
    });
  });
}

function promiseGiroSolutionIntent(funcToCall, uuid, searchId) {
  return new Promise((resolve, reject) => {
    funcToCall(uuid, searchId, (res) => {
      if (res) {
        // console.log(res);
        resolve(res);
      } else {
        reject("Error");
      }
    });
  });
}

const BookingPayment = ({
  sum,
  isDisabled,
  onPrev,
  cart,
  goToProducts,
  onClick,
  paymentKind,
  setPaymentKind,
  isBooking,
  setIsBooking,
  onSaveBookingForPayment,
  values,
  stripePromise,
  onBookingWithShopOrder,
  onCompleted,
  handleChangeRevocation,
  acceptRevocation,
  handleChangeAgb,
  acceptAgb,
  onPayOne,
  additionalPersons,
}) => {
  const [instance, setInstance] = useState(null);
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);
  const stripe = useStripe();
  const elements = useElements();
  const [cardInputComplete, setCardInputComplete] = useState(false);
  const [bookingIdForPayPalRedirect, setBookingIdForPayPalRedirect] = useState(
    null
  );
  const [cardError, setCardError] = useState(null);

  useEffect(() => {
    setCardInputComplete(false);
  }, [paymentKind]);

  const buy = async () => {
    setCardError(null);
    if (sum === 0 || paymentKind === "ec") {
      return createZeroCostBooking();
    }

    if (paymentKind === "creditCard") {
      return createStripeIntent("creditCard");
    }

    if (paymentKind === "giroPay") {
      return createStripeIntent("giroPay");
    }

    if (paymentKind === "giroSolution") {
      return createGiroSolutionIntent();
    }

    /*
    if (paymentKind === "giroPay") {
      return createStripeGiroPayOrder();
      // return onSaveBookingForPayment(sum * 100, createStripeSource);
    }
    */
  };

  const getGiroSolutionLink = (uuid, searchId, callback) => {
    const body = {
      expectedSum: sum * 100,
      cart,
      uuid,
      searchId
    };
    API.post("bookings/shop/check-out/girosolution-link/", body)
      .then((result) => {
        const giroSolutionResult = result.data ? result.data : null;
        // console.log(giroSolutionResult);

        let redircetUrl = null;

        if (giroSolutionResult && giroSolutionResult.redircetUrl) {
          redircetUrl = giroSolutionResult.redircetUrl;
        }

        callback(redircetUrl);
      })
      .catch((error) => {});
  };

  const createGiroSolutionIntent = async () => {
    try {
    const resBooking = await promiseBookingWithShopOrder(
      onBookingWithShopOrder
    );
    const redirectUrl = await promiseGiroSolutionIntent(
      getGiroSolutionLink,
      resBooking.booking.uuid,
      resBooking.booking.SearchId
    );
    console.log("REDIRECT TO...", redirectUrl);
    window.location.href = redirectUrl;
  } catch (err) {
    console.log("ERROR");
  }
  }

  const createZeroCostBooking = async () => {
    try {
      const res = await promiseBookingWithShopOrder(onBookingWithShopOrder);
      if (res && res.booking && res.booking.uuid) {
        onCompleted(res.booking.uuid);
      }
    } catch (err) {}
  };

  const createStripeGiroPayOrder = async () => {
    try {
      const res = await promiseBookingWithShopOrder(onBookingWithShopOrder);
      onSaveBookingForPayment();
      createStripeSource(res.booking.uuid);
    } catch (err) {}
  };

  const createPayPalOrder = async (data, actions) => {
    try {
      const res = await promiseBookingWithShopOrder(onBookingWithShopOrder);
      setBookingIdForPayPalRedirect(res.booking.uuid);
      return actions.order.create({
        purchase_units: [
          {
            description: businessUnit.MachineName,
            custom_id: res.booking.uuid,
            items: res.shopOrder.ShopOrderItems.map((item) => ({
              name: item.Title,
              category: "DIGITAL_GOODS",
              quantity: item.Quantity,
              unit_amount: {
                currency_code: "EUR",
                value: item.Price,
              },
            })),
            amount: {
              currency_code: "EUR",
              value: res.shopOrder.ShopOrderItems.reduce(
                (a, b) => a + b.Quantity * b.Price,
                0
              ),
              breakdown: {
                item_total: {
                  currency_code: "EUR",
                  value: res.shopOrder.ShopOrderItems.reduce(
                    (a, b) => a + b.Quantity * b.Price,
                    0
                  ),
                },
              },
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      });
    } catch (err) {}
  };

  const getStripeIntent = (uuid, stripePaymentKind, callback) => {
    const body = {
      expectedSum: sum * 100,
      cart,
      uuid,
      paymentKind: stripePaymentKind,
    };
    API.post("bookings/shop/check-out/stripe-intent/", body)
      .then((result) => {
        const stripeResult = result.data ? result.data : null;
        // console.log(stripeResult);

        let clientSecret = null;

        if (stripeResult && stripeResult.client_secret) {
          clientSecret = stripeResult.client_secret;
        }

        callback(clientSecret);
      })
      .catch((error) => {});
  };

  const createStripeIntent = async (stripePaymentKind) => {
    // console.log("CREATE STRIPE INTENT");
    try {
      const resBooking = await promiseBookingWithShopOrder(
        onBookingWithShopOrder
      );
      const clientSecret = await promiseStripeIntent(
        getStripeIntent,
        resBooking.booking.uuid,
        stripePaymentKind
      );
      // const intentId = clientSecret.split("_secret")[0];

      if (stripePaymentKind === "creditCard") {
        makeCardTransaction(clientSecret, () => {
          onCompleted(resBooking.booking.uuid);
        });
      }

      if (stripePaymentKind === "giroPay") {
        makeGiroPayTransaction(clientSecret, resBooking.booking.uuid);
      }
    } catch (err) {
      console.log("ERROR");
    }
  };

  const makeCardTransaction = async (client_secret, onSuccess) => {
    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${values.FirstName} ${values.LastName}`,
        },
      },
    });
    // console.log("PAYMENT RESULT", result);
    if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
      onSuccess();
    } else {
      setIsBooking(false);
      setCardError(
        "Bitte überprüfen Sie Ihre Angaben oder wählen Sie eine andere Zahlungsart"
      );
    }
  };

  const makeGiroPayTransaction = async (client_secret, uuid) => {
    const result = await stripe.confirmGiropayPayment(client_secret, {
      payment_method: {
        billing_details: {
          name: `${values.FirstName} ${values.LastName}`,
        },
      },
      return_url: window.location.origin + "/kasse/" + uuid,
    });
    if (result.error) {
      console.log("ERROR");
    }
  };

  const createStripeSource = async (uuid) => {
    stripe
      .createSource({
        type: "giropay",
        amount: sum * 100,
        currency: "eur",
        statement_descriptor: uuid,
        owner: {
          name: `${values.FirstName} ${values.LastName}`,
        },
        redirect: {
          return_url: window.location.origin + "/kasse/" + uuid,
        },
      })
      .then(function (result) {
        // handle result.error or result.source
        // console.log("ERROR", result.error);
        // console.log("SOURCE", result.source);
        if (result.source) {
          window.location.href = result.source.redirect.url;
        }
      });
  };

  if ((!sum && sum !== 0) || !cart || !cart.length) {
    return null;
  }

  let buttonDisabled;

  if (sum > 0) {
    buttonDisabled =
      !stripe ||
      !paymentKind ||
      isDisabled ||
      (paymentKind === "creditCard" && !cardInputComplete) ||
      (businessUnit.RevocationRightCheckboxLabel && !acceptRevocation);
  } else {
    buttonDisabled = isDisabled;
  }

  buttonDisabled =
    buttonDisabled || (businessUnit.AgbCheckboxLabel && !acceptAgb);

  return (
    <div className={classes.root}>
      <Box pt={2} pb={3}>
        <BookingFormSummary
          booking={{ ...values, AdditionalPersons: additionalPersons }}
        />
      </Box>
      <Box pb={3}>
        <BookingFormSelectionSummary booking={values} />
      </Box>
      <Box pb={3}>
        <BookingShopCart cart={cart} goToProducts={goToProducts} />
      </Box>
      <Box>
        <BookingShopTotalPrice cart={cart} />
      </Box>

      <BookingAcceptBox
        what="Agb"
        handleChange={handleChangeAgb}
        accept={acceptAgb}
      />

      {sum > 0 && (
        <>
          <BookingAcceptBox
            what="RevocationRight"
            handleChange={handleChangeRevocation}
            accept={acceptRevocation}
          />

          <Box pb={3}>
            <BookingPaymentKinds
              paymentKind={paymentKind}
              setPaymentKind={setPaymentKind}
              createPayPalOrder={createPayPalOrder}
              setCardInputComplete={setCardInputComplete}
              bookingIdForPayPalRedirect={bookingIdForPayPalRedirect}
              onCompleted={onCompleted}
              setIsBooking={setIsBooking}
              acceptRevocation={acceptRevocation}
              revocationNeeded={Boolean(
                businessUnit.RevocationRightCheckboxLabel
              )}
              acceptAgb={acceptAgb}
              agbNeeded={Boolean(businessUnit.AgbCheckboxLabel)}
              onPayOne={onPayOne}
              sum={sum}
            />

            {Boolean(cardError) && (
              <Typography variant="body2" className={classes.cardError}>
                {cardError}
              </Typography>
            )}
          </Box>
        </>
      )}

      {(paymentKind !== "paypal" || sum === 0) && (
        <div className={classes.btnWrapper}>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            className={classes.btnBooking}
            classes={isBooking ? { label: classes.labelBooking } : undefined}
            onClick={buy}
            style={
              buttonDisabled
                ? undefined
                : {
                    backgroundColor: businessUnit.ColorButtonBackground,
                    color: businessUnit.ColorButtonText || "#fff",
                  }
            }
          >
            {sum > 0 ? "Jetzt kaufen" : "Jetzt buchen"}
          </Button>
          {isBooking && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      )}

      {Boolean(onPrev) && (
        <Button
          variant="outlined"
          fullWidth
          className={classes.btnCancel}
          onClick={onPrev}
        >
          Zurück
        </Button>
      )}
    </div>
  );
};

export default BookingPayment;
