import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
} from "@material-ui/core";
import API from "../../utils/API";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  bodyText: {
    lineHeight: 1.4,
  },
}));

const Page = ({ match }) => {
  const [article, setArticle] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setArticle(null);
    if (!match.params.slug) {
      return;
    }

    API.get("pages/slug/" + match.params.slug)
      .then((newsData) => {
        // console.log(newsData);
        const newsResult = newsData.data ? newsData.data : null;
        setArticle(newsResult);
      })
      .catch((error) => {
        setArticle(null);
      });
  }, [match.params.id]);

  // console.log(article);

  if (!article) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                {article.Title}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.bodyText}
              >
                <ReactMarkdown source={article.Body} />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Page;
