import React from "react";
import { Typography, Box, Button, makeStyles, Paper } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    width: 60,
    height: 60,
    border: "2px solid transparent",
    borderColor: green[500],
    borderRadius: "50%",
    padding: theme.spacing(1),
    color: green[500],
    marginBottom: theme.spacing(4),
  },
}));

const ShopOrderCheckInOutSuccess = ({ onNextScan, onCancel }) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Paper>
          <Box p={8} display="flex" flexDirection="column" alignItems="center">
            <CheckIcon className={classes.icon} />
            <Typography variant="body1">
              <strong>Nächsten scannen?</strong>
            </Typography>
            <Box mt={4}>
              <Button
                variant="outlined"
                onClick={onCancel}
                className={classes.btn}
              >
                Nein
              </Button>
              <Button
                variant="outlined"
                onClick={onNextScan}
                className={classes.btn}
              >
                Ja
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ShopOrderCheckInOutSuccess;
