import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Container,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";
import useValues from "@bit/wamedia.tasso.use-values";
import { useSelector } from "react-redux";
import API from "../../utils/API";
import { green, red } from "@material-ui/core/colors";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  success: {
    color: green[500],
  },
  error: {
    color: red[500],
  },
}));

const initialValues = {
  email: "",
  code: "",
};

const MyBookingsRequest = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [codeSuccess, setCodeSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setValues(initialValues);
    setError(null);
    setEmailSuccess(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSendEmail = () => {
    if (!values.email) {
      return;
    }
    setEmailSuccess(false);
    setIsSending(true);
    API.post("/booking-reports/new-report", {
      email: values.email,
    })
      .then((result) => {
        const reportResult = result.data ? result.data : null;
        setIsSending(false);
        if (reportResult.result === "OK") {
          setEmailSuccess(true);
        } else {
          setError(`Es ist ein Fehler aufgetreten`);
        }
        // handleClose();
      })
      .catch((error) => {
        setError(`Es ist ein Fehler aufgetreten`);
        setIsSending(false);
        // handleClose();
      });
  };

  const handleShowBookings = () => {
    setIsSending(true);
    API.post("/booking-reports/report/uuid", {
      email: values.email,
      code: values.code,
    })
      .then((result) => {
        const reportResult = result.data ? result.data : null;
        setIsSending(false);
        if (reportResult.uuid) {
          setCodeSuccess(reportResult.uuid);
        } else {
          setError(`Es ist ein Fehler aufgetreten`);
        }
      })
      .catch((error) => {
        setError(`Es ist ein Fehler aufgetreten`);
        setIsSending(false);
      });
  };

  if (!businessUnit) {
    return null;
  }

  if (codeSuccess) {
    return <Redirect push to={"/meine-buchungen/uebersicht/" + codeSuccess} />;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={{ xs: 1, sm: 10 }}
          textAlign="center"
        >
          <Box mt={3}>
            <Typography variant="h1">Meine Buchungen</Typography>
          </Box>
          <Box my={3}>
            <Typography variant="body2">
              Um eine Übersicht Ihrer aktuellen Buchungen zu erstellen, müssen
              Sie nur die E-Mail-Adresse eingeben, mit der Sie die Buchungen
              durchgeführt haben. Sie erhalten umgehend einen
              Verifizierungs-Code per E-Mail zugesendet mit dem Sie Ihre
              Buchungen abrufen und erneut herunterladen können.
            </Typography>
          </Box>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: businessUnit.ColorButtonBackground,
            }}
          >
            Buchungsübersicht anfordern
          </Button>
        </Box>
      </Container>
      <Dialog
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
      >
        <DialogTitle>
          {!emailSuccess && <>Verifizierungs-Code anfordern</>}
          {emailSuccess && <>Buchungsübersicht anzeigen</>}
        </DialogTitle>
        <DialogContent>
          {Boolean(error) && (
            <Box mb={3}>
              <Paper>
                <Box px={3} py={1} textAlign="center">
                  <Typography variant="body2" className={classes.error}>
                    {error}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          )}

          {!isSending && !emailSuccess && (
            <>
              <DialogContentText>
                Bitte geben Sie die E-Mail-Adresse ein mit der Sie gebucht
                haben.
              </DialogContentText>
              <TsTextField
                value={values.email}
                handleChange={handleChangeValue}
                name="email"
                label="E-Mail-Adresse"
              />
            </>
          )}

          {!isSending && emailSuccess && (
            <>
              <DialogContentText>
                Bitte geben Sie den <strong>Verifizierungs-Code</strong> ein,
                der gerade an {values.email} gesendet wurde.
              </DialogContentText>
              <TsTextField
                value={values.code}
                handleChange={handleChangeValue}
                name="code"
                label="Verifizierungs-Code"
              />
            </>
          )}

          {isSending && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={4}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>

          {!emailSuccess && (
            <Button
              disabled={isSending || !values.email}
              onClick={handleSendEmail}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: businessUnit.ColorButtonBackground,
              }}
            >
              Senden
            </Button>
          )}

          {emailSuccess && (
            <Button
              disabled={isSending || !values.code}
              onClick={handleShowBookings}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: businessUnit.ColorButtonBackground,
              }}
            >
              Übersicht anzeigen
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyBookingsRequest;
