const initState = null;

const savedBookingForPaymentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SAVED_BOOKING_FOR_PAYMENT":
      return action.booking;
    default:
      return state;
  }
};

export default savedBookingForPaymentReducer;
