import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  makeStyles,
  Paper,
} from "@material-ui/core";
import API from "../../utils/API";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  bodyText: {
    lineHeight: 1.4,
  },
}));

const Hinweise = ({ match }) => {
  const classes = useStyles();
  const businessUnit = useSelector((state) => state.businessUnit);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (businessUnit && businessUnit.isBusinessUnitSelect) {
      setRedirect(true);
    }
  }, [businessUnit]);

  if (redirect) {
    return <Redirect to="/" />;
  }

  if (!businessUnit || businessUnit.isBusinessUnitSelect) {
    return null;
  }

  return (
    <>
      <Container maxWidth="md">
        <Box py={{ xs: 2, md: 5 }} px={{ xs: 0, md: 11 }}>
          <Paper>
            <Box p={{ xs: 2, md: 5 }}>
              <Typography variant="h1" className={classes.title}>
                {businessUnit.DeliveryLinkTitle || "Hinweise zur Anlieferung"}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.bodyText}
              >
                <ReactMarkdown source={businessUnit.DeliveryInformation} />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default Hinweise;
